/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable default-case */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid, Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import Question from './Question';
import Answers from './Answers';
import NextButton from './NextButton';
import Media from './Media';
import DescriptionDialog from './DescriptionDialog';
import examType1Services from '../../../../../../../redux/services/examType1Services';

const useStyles = makeStyles((theme) => ({
  root: {
    margin: '1em 0',
    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // width: '100%',
    padding: '40px',
  },
  media: {
    textAlign: 'center',
    margin: '1em 0 1em 0',
  },
  nextButton: {
    // padding: '0 2em',
  },
  indexQuestion: {
    marginBottom: '30px',
    alignSelf: 'center',
  },
  subcategory: {
    marginBottom: '30px',
    marginLeft: '20px',
    fontSize: '18px',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
}));

const TestBody = ({
  testData, multiQuestionId, descriptionKN, descriptionRC, answers, testId,
}) => {
  const [open, setOpen] = useState(true);
  const currentQuestion = localStorage.getItem('currentQuestion') !== null ? parseInt(localStorage.getItem('currentQuestion'), 10) : 0;
  const [indexSubcategory, setIndexSubcategory] = useState(0);
  const [errorSnackBar, setErrorSnackBar] = useState(null);

  const classes = useStyles();
  useEffect(() => {
    setOpen(true);
  }, [testData[currentQuestion].category]);

  useEffect(() => {
    if (testData[currentQuestion].subcategory !== undefined) {
      setIndexSubcategory(indexSubcategory + 1);
    }
  }, [testData[currentQuestion].subcategory]);

  useEffect(() => {
    let isCancelled = false;
    const body = {
      answers,
      time: 0,
      isFinished: false,
    };
    if (!isCancelled && testId && body.answers && body.answers !== null && body.answers.length > 0) {
      examType1Services.sendTest(testId, body)
        .then(
          (res) => {
            // console.log(res);
          },
          (error) => {
            if (!isCancelled) {
              console.error(error);
            }
          },
        )
        .catch(() => {
          setErrorSnackBar({ code: 1601, message: 'Problem sending results.', severity: 'error' });
        });
    }
    return () => {
      isCancelled = true;
    };
  }, [currentQuestion]);

  return (
    <Grid
      container
      direction="column"
      className={classes.root}
      justifyContent="center"
    >
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <div className={classes.indexQuestion}>{`Question : ${currentQuestion + 1} / ${testData.length + 1}`}</div>
      {(testData[currentQuestion].subcategory !== undefined && testData[currentQuestion].subcategory.length > 0)
      && (
      <div className={classes.subcategory}>
        {indexSubcategory}
        .
        {' '}
        {testData[currentQuestion].subcategory}
      </div>
      )}
      {testData[currentQuestion].media.filename && (
        <Grid item xs={12} md={12} className={classes.media}>
          <Media
            // eslint-disable-next-line max-len
            audioAttribution={testData[currentQuestion].media.audioAttribution}
            filename={testData[currentQuestion].media.filename}
            type={testData[currentQuestion].media.type}
          />
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <Question
          question={testData[currentQuestion].name}
          type={testData[currentQuestion].type}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Answers
          questionId={testData[currentQuestion]._id}
          question={testData[currentQuestion].name}
          answers={testData[currentQuestion].answers}
          type={testData[currentQuestion].type}
          multiQuestionId={multiQuestionId}
        />
      </Grid>
      <Grid item xs={12} md={12} className={classes.nextButton}>
        <NextButton className={classes.root} />
      </Grid>
      {
        (() => {
          switch (testData[currentQuestion].category) {
            case 'KN':
              return <DescriptionDialog title="VOCABULAIRE" description={descriptionKN} open={open} setOpen={setOpen} />;
            case 'RC':
              return <DescriptionDialog title="COMPREHENSION DE TEXTE" description={descriptionRC} open={open} setOpen={setOpen} />;
            case 'OC':
              return <DescriptionDialog title="COMPREHENSION ORALE" description="super description" open={open} setOpen={setOpen} />;
          }
        })()
      }
    </Grid>
  );
};

TestBody.propTypes = {
  testData: PropTypes.array.isRequired,
  descriptionKN: PropTypes.string.isRequired,
  descriptionRC: PropTypes.string.isRequired,
  answers: PropTypes.array.isRequired,
  testId: PropTypes.number.isRequired,
  multiQuestionId: PropTypes.number.isRequired,
};

const actionCreators = {
};

function mapState(state) {
  const { multiQuestionId } = state.exam;
  return {
    multiQuestionId,
  };
}
export default connect(mapState, actionCreators)(TestBody);
