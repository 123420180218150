import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, Button, Typography,
} from '@material-ui/core';

const DescriptionDialog = ({
  description, title, open, setOpen,
}) => (
  <Dialog
    open={open}
    onClose={() => setOpen(false)}
  >
    <DialogTitle>{title}</DialogTitle>
    <Typography style={{ width: '90%', margin: '5%' }}>{description}</Typography>
    <Button onClick={() => setOpen(false)} autoFocus>
      I UNDERSTAND
    </Button>
  </Dialog>
);

DescriptionDialog.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default DescriptionDialog;
