/* eslint-disable react/destructuring-assignment */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import SignUpCode from './components/SignUpCode';
import MWForm from './components/MWForm';
import signUpServices from '../../redux/services/signupServices';
import userActions from '../../redux/actions/userActions';
import companyServices from '../../redux/services/companyServices';

const useStyles = makeStyles((theme) => ({
  button: {
    marginRight: theme.spacing(1),
  },
  completed: {
    display: 'inline-block',
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

function getSteps() {
  return ['Code d\'accès', 'Créer votre compte'];
}

const Wizard = (props) => {
  const args = props;
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [codeName, setCodeName] = useState(args.graphData && args.graphData.code != null ? args.graphData.code : '');
  const [semester, setSemester] = useState(args.graphData && args.graphData.sem != null && typeof (args.graphData.sem) === 'number' ? args.graphData.sem : 1);
  const [codeData, setCodeData] = useState(null);
  const [semesters, setSemesters] = useState([0]);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [needsAnalysis, setNeedsAnalysis] = useState(false);
  const [error, setError] = useState(false);
  const steps = getSteps();

  const handleNext = () => {
    if (codeName.length < 1) {
      setError(true);
      return;
    }
    signUpServices.getCode(codeName)
      .then(
        (data) => {
          if (data.company.type === '2') {
            setCodeData(data);
          } else {
            setError(true);
            return;
          }
          if (data.needs) {
            setNeedsAnalysis(true);
          }
          if (args.graphData !== null) {
            const encryptedPassword = args.graphData.id;
            const newUser = {
              email: args.graphData.userPrincipalName,
              password: encryptedPassword,
              firstname: args.graphData.givenName,
              name: args.graphData.surname,
              type: 4,
              semester,
              company: data.company._id,
              code: data._id,
            };
            props.createUser(newUser);
          } else {
            setActiveStep(activeStep + 1);
          }
        },
        () => {
          setError(true);
        },
      )
      .catch(() => {
        setErrorSnackBar({ code: 2801, message: 'Missing code data.', severity: 'warning' });
      });
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <SignUpCode
            graphData={args.graphData}
            handleNext={handleNext}
            error={error}
            code={codeName}
            setCodeName={(code) => setCodeName(code)}
            setSemester={(_semester) => setSemester(_semester)}
          />
        );
      case 1:
        return (
          <MWForm
            handleBack={handleBack}
            semesters={semesters}
            needsAnalysis={needsAnalysis}
            codeId={codeData && codeData._id}
            company={codeData && codeData.company}
            placementtest={codeData.placementtest !== undefined ? codeData.placementtest : true}
          />
        );
      default:
        return 'Unknown step';
    }
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  useEffect(() => {
    if (args.graphData) {
      if (args.graphData.code !== null && args.graphData.code.length > 0) {
        if (args.graphData.sem !== null && typeof (args.graphData.sem) === 'number') {
          handleNext();
        }
      }
    }
  }, []);

  useEffect(async () => {
    if (codeData) {
      const semestersList = [0];
      const today = new Date();
      const companyData = await companyServices.getCompanyDataById(codeData.company._id);
      const allowedSemesters = companyData && companyData.semesters.length > 0 ? [] : [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
      companyData.semesters.forEach((_semester) => {
        const begin = new Date(_semester.beginDate);
        const end = new Date(_semester.endDate);
        begin.setHours(0);
        end.setHours(0);
        if ((today >= begin && today <= end) || (!_semester.beginDate || !_semester.endDate)) {
          allowedSemesters.push(_semester.semester);
        }
      });
      codeData.company.idGroups.forEach((group) => {
        if (!semestersList.includes(group.semester) && allowedSemesters.includes(group.semester)) {
          semestersList.push(group.semester);
        }
      });
      semestersList.sort((a, b) => a - b);
      setSemesters(semestersList);
    }
  }, [codeData]);

  return (
    <div className={classes.paper}>
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      { args.graphData ? (
        <Step>
          <StepButton onClick={handleStep(steps[0].index)} completed={false}>
            {steps[0].label}
          </StepButton>
        </Step>
      )
        : (
          <Stepper activeStep={activeStep} style={{ backgroundColor: 'rgb(0, 0, 0, 0' }}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepButton onClick={handleStep(index)} completed={false}>
                  {label}
                </StepButton>
              </Step>
            ))}
          </Stepper>
        )}
      <div>
        {getStepContent(activeStep)}
      </div>
    </div>
  );
};

Wizard.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  createUser: PropTypes.func.isRequired,
};

function mapState(state) {
  const { azureToken } = state.auth;
  return { azureToken };
}

const actionCreators = {
  createUser: userActions.createUser,
};

export default connect(mapState, actionCreators)(Wizard);
