/* eslint-disable no-unused-expressions */
import React from 'react';
import PropTypes from 'prop-types';
import SoundPlayer from './SoundPlayer';
import DisplayImage from './DisplayImage';

const Media = ({ filename, type, audioAttribution }) => {
  switch (type) {
    case 'img':
      return <DisplayImage audioAttribution={audioAttribution} filename={filename} />;
    case 'audio':
      return <SoundPlayer audioAttribution={audioAttribution} filename={filename} />;
    default:
      return (<p>Error while loading media</p>);
  }
};

Media.defaultProps = {
  filename: '',
  type: '',
  audioAttribution: '',
};

Media.propTypes = {
  filename: PropTypes.string,
  type: PropTypes.string,
  audioAttribution: PropTypes.string,
};

export default Media;
