/* eslint-disable react/prop-types */
import { Grid } from '@material-ui/core';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TotalProgressWidget from '../../../Dashboard/components/TotalQuizzesProgressWidget';
import TotalUnitProgressWidget from '../../../Dashboard/components/TotalUnitProgressWidget';
import TotalTimeWidget from '../../../Dashboard/components/TotalTimeWidget';
import { Loading } from '../../../../helpers/utils';

const useStyles = makeStyles(() => ({
  root: {
    margin: '1em 0 1em 0',
  },
  marginTop: {
    marginTop: '1em',
  },
}));

const GlobalResults = ({
  studentData, currentGroup, studentId, data,
}) => {
  const classes = useStyles();

  return (
    <Grid
      container
      direction="row"
      className={classes.root}
      spacing={3}
    >
      {data && data.length > 0
        ? (
          <>
            <Grid item xs={12} md={6} className={classes.marginTop}>
              <TotalProgressWidget
                studentData={studentData}
                currentGroup={currentGroup}
                studentId={studentId}
                data={data}
              />
            </Grid>
            <Grid item xs={12} md={6} className={classes.marginTop}>
              <TotalUnitProgressWidget
                studentData={studentData}
                currentGroup={currentGroup}
                studentId={studentId}
                data={data}
              />
            </Grid>
            <Grid item xs={12} md={12} className={classes.marginTop}>
              <TotalTimeWidget
                studentData={studentData}
                currentGroup={currentGroup}
                studentId={studentId}
                data={data}
              />
            </Grid>
          </>
        )
        : <Loading />}
    </Grid>
  );
};

export default GlobalResults;
