import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid } from '@material-ui/core';
import ReactPlayer from 'react-player';
import uiActions from '../../redux/actions/uiActions';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '2em',
    width: '100%',
    maxWidth: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}));

const Player = ({ url }) => {
  const classes = useStyles();

  return (
    <Container style={{ zIndex: 10 }}>
      <Grid container direction="row" spacing={2} className={classes.root}>
        <ReactPlayer
          url={url}
          controls
          loop
          width="100%"
          playing
          className={classes.root}
        />
      </Grid>
    </Container>
  );
};

Player.propTypes = {
  url: PropTypes.string.isRequired,
};

const actionCreators = {
  changePageTitle: uiActions.changePageTitle,
};

export default connect(null, actionCreators)(Player);
