/* eslint-disable no-console */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
} from '@material-ui/core';
import config from '../../../../config';

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
    height: 250,
  },
  media: {
    height: 200,
  },
  card: {
    height: 120,
  },
  subtitle: {
    fontSize: '0.8em',
  },
});

const ResourceCard = ({
  title, filename, type, icon, thumbnail, setUrl,
}) => {
  const classes = useStyles();
  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Card style={{ marginBottom: '1em' }} className={classes.card}>
        {
          type === 'video'
            ? (
              <CardActionArea
                onClick={() => setUrl(`${config.OBJECT_URL}/resources/video/${filename}`)}
                target="_blank"
                style={{ alignItems: 'center', height: '100%', width: '100%' }}
              >
                {
            thumbnail
            && (
              <CardMedia
                className={classes.media}
                image={`${config.OBJECT_URL}/thumbnail/${thumbnail}`}
                title="Contemplative Reptile"
              />
            )
          }
                <CardContent>
                  <Grid container direction="row">
                    <Grid item md={2} style={{ marginTop: '5px' }}>
                      {icon}
                    </Grid>
                    <Grid item md={10}>
                      <Typography variant="h6">
                        {title.replaceAll('_', ' ')}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            )
            : (
              <CardActionArea
                component="a"
                href={`${config.OBJECT_URL}/resources/${type}/${filename}`}
                target="_blank"
                style={{ alignItems: 'center', height: '100%', width: '100%' }}
              >
                {
            thumbnail
            && (
              <CardMedia
                className={classes.media}
                image={`${config.OBJECT_URL}/thumbnail/${thumbnail}`}
                title="Contemplative Reptile"
              />
            )
          }
                <CardContent>
                  <Grid container direction="row">
                    <Grid item md={2} style={{ marginTop: '5px' }}>
                      {icon}
                    </Grid>
                    <Grid item md={10}>
                      <Typography variant="h6">
                        {title.replaceAll('_', ' ')}
                      </Typography>
                    </Grid>
                  </Grid>
                </CardContent>
              </CardActionArea>
            )
        }
      </Card>
    </Grid>
  );
};

ResourceCard.defaultProps = {
  title: 'Default Title',
  filename: 'default.pdf',
  type: 'pdf',
};

ResourceCard.propTypes = {
  title: PropTypes.string,
  filename: PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.node.isRequired,
  thumbnail: PropTypes.string.isRequired,
  setUrl: PropTypes.func.isRequired,
};

export default ResourceCard;
