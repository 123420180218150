/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable object-curly-newline */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import axios from 'axios';
import SearchIcon from '@material-ui/icons/Search';
import PlayArrow from '@material-ui/icons/PlayArrow';
import {
  Button, Divider, InputAdornment, TextField, Typography,
} from '@material-ui/core';
import uiActions from '../../redux/actions/uiActions';

const Dictionnary = ({ dictionnary, changeDictionnaryAlign, dictionnaryAlign }) => {
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const [synonyms, setSynonyms] = useState([]);
  const [antonyms, setAntonyms] = useState([]);
  const [searchWord, setSearchWord] = useState('');

  function getMeaning() {
    setError(false);
    axios.get(
      `https://api.dictionaryapi.dev/api/v2/entries/en_US/${searchWord}`,
    )
      .then((response) => {
        setData(response.data[0]);
        console.log(response.data[0]);
        if (response.data[0] && response.data[0].meanings) {
          setSynonyms(response.data[0].meanings[0].synonyms.slice(0, 5));
          setAntonyms(response.data[0].meanings[0].antonyms.slice(0, 5));
        }
        setError(false);
      })
      .catch(() => {
        setData(null);
        setSynonyms([]);
        setError(true);
      });
  }

  function playAudio() {
    let found = false;
    data.phonetics.forEach((phonetic) => {
      if (found === false && phonetic.audio && phonetic.audio.length > 0) {
        const audio = new Audio(phonetic.audio);
        audio.play();
        found = true;
      }
    });
  }

  useEffect(() => {
    setError(false);
    if (searchWord && searchWord.length > 2) {
      getMeaning();
    } else {
      setData(null);
    }
  }, [searchWord]);

  return null;
  /*
  if (dictionnary === false) {
    return null;
  }
  return (
    open
      ? (
        <div style={{
          display: 'flex', position: 'fixed', minWidth: 300, minHeight: 550, bottom: 20, right: !dictionnaryAlign ? 20 : null, left: dictionnaryAlign ? 20 : null, backgroundColor: 'white', borderRadius: 45, borderWidth: 1, borderTopWidth: 2, borderBottomWidth: 2, borderStyle: 'solid', borderColor: '#1588af', zIndex: 1150,
        }}
        >
          <div style={{
            display: 'flex', flexDirection: 'column', width: '100%', backgroundColor: 'white', borderRadius: 40, borderWidth: 2, borderTopWidth: 5, borderBottomWidth: 5, borderStyle: 'solid', borderColor: '#1bb1e4', zIndex: 1150, padding: 3, justifyContent: 'space-between', alignItems: 'center',
          }}
          >
            <div style={{ width: '90%' }}>
              <TextField
                variant="standard"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon style={{ color: '#1588af' }} />
                    </InputAdornment>
                  ),
                }}
                margin="normal"
                required
                error={error}
                fullWidth
                id="search"
                placeholder="Search.."
                name="search"
                value={searchWord || ''}
                onChange={(e) => {
//                  if (e.target.value.trim().length <= 12) {
                  setSearchWord(e.target.value.trim());
//                }
                }}
                autoFocus
              />
            </div>
            {
              data && data !== null
                ? (
                  <div style={{
                    maxHeight: 500, minHeight: '80%', maxWidth: 350, overflowY: 'scroll', display: 'flex', flexDirection: 'column', width: '90%', marginLeft: '5%', marginBottom: 5,
                  }}
                  >
                    <div style={{ display: 'flex', width: '100%', flexDirection: 'row', justifyContent: 'space-between' }}>
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <h4 style={{ fontWeight: 'bold', marginRight: 10 }}>
                          {searchWord.toUpperCase()}
                          {`${data.meanings && data.meanings[0].partOfSpeech ? ':' : ''}`}
                        </h4>
                        {
                          data.meanings && data.meanings[0].partOfSpeech
                            ? <h4 style={{ fontStyle: 'italic', marginRight: 20, fontWeight: 'normal' }}>{data.meanings && data.meanings[0].partOfSpeech ? data.meanings[0].partOfSpeech : ''}</h4>
                            : null
                        }
                      </div>
                      {
                        data.phonetic
                          ? (
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                              <h4 style={{ fontStyle: 'italic', textAlign: 'end', marginRight: 20, fontWeight: 'normal' }}>{data.phonetic}</h4>
                              {
                                data.phonetics && data.phonetics[0].audio && data.phonetics[0].audio.length > 1
                                ? (
                                  <Button
                                    onClick={() => playAudio()}
                                    color="primary"
                                    variant="contained"
                                    style={{
                                    width: 30, height: 30, minHeight: 30, borderRadius: 100, display: 'flex', justifyContent: 'center', alignItems: 'center', margin: 0, padding: 0, minWidth: 30, maxWidth: 30,
                                  }}
                                  >
                                    <PlayArrow style={{ color: '#f5f5f5' }} />
                                  </Button>
)
                                : null
                              }
                            </div>
                          )
                          : null
                      }
                    </div>
                    {
                      synonyms.length > 0
                        ? (
                          <div style={{ display: 'flex', marginTop: -10, flexDirection: 'row', width: '100%', lineHeight: 1.2 }}>
                            <h4 style={{ marginRight: 10, fontWeight: 'normal' }}>{`${synonyms.length > 1 ? 'Synonyms:' : 'Synonym:'}`}</h4>
                            <h4 style={{ fontStyle: 'italic', marginRight: 10, fontWeight: 'normal' }}>{synonyms ? synonyms.join(', ') : ''}</h4>
                          </div>
                        )
                        : null
                    }
                    {
                      antonyms.length > 0
                        ? (
                          <div style={{ display: 'flex', marginTop: -20, flexDirection: 'row', width: '100%', lineHeight: 1.2 }}>
                            <h4 style={{ marginRight: 10, fontWeight: 'normal' }}>{`${antonyms.length > 1 ? 'Antonyms:' : 'Antonym:'}`}</h4>
                            <h4 style={{ fontStyle: 'italic', marginRight: 10, fontWeight: 'normal' }}>{antonyms ? antonyms.join(', ') : ''}</h4>
                          </div>
                        )
                        : null
                    }
                    <Divider />
                    {
                      data.meanings && data.meanings[0].definitions.length > 0
                        ? <h3 style={{ marginRight: 10, fontWeight: 'bolder', marginBottom: 0 }}>Definitions:</h3>
                      : null
                    }
                    {
                      data.meanings[0].definitions.slice(0, 5).map((definition, index) => (
                        <>
                          <div style={{ display: 'flex', flexDirection: 'column', width: '80%', justifyContent: 'space-between' }}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                              <h4 style={{ margin: 10, backgroundColor: '#1bb1e4', padding: 5, color: 'white', fontWeight: 'bold', borderRadius: 50 }}>{`#${index + 1}`}</h4>
                              <h4 style={{ marginRight: 10, marginTop: 15 }}>{`${definition.definition}`}</h4>
                            </div>
                            {
                              definition.example && definition.example.length > 0
                              ? <h4 style={{ fontStyle: 'italic', textAlign: 'end', width: '100%', marginLeft: 30, marginTop: 0, marginBottom: 15, fontWeight: 'normal' }}>{`Example: ${definition.example}`}</h4>
                              : null
                            }
                          </div>
                          <Divider style={{ marginRight: 20, marginLeft: 20 }} />
                        </>
                      ))
                    }
                  </div>
                )
                : (
                  <div style={{
                    height: '100%', width: 300, padding: 50, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',
                  }}
                  >
                    <img style={{ width: '60%', marginBottom: 25 }} src="../../img/logo.png" title="logo" alt="logo" />
                    <Typography
                      variant="h6"
                      style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: 25, color: '#1588af' }}
                    >
                      Search for a word to get the definition and more !
                    </Typography>
                    <Typography
                      variant="span"
                      style={{ textAlign: 'center', fontStyle: 'italic', marginBottom: 25, color: '#1588af' }}
                    >
                      You can disable this feature in the menu.
                    </Typography>
                  </div>
                )
            }
            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Button
                onClick={changeDictionnaryAlign}
                color="default"
                style={{
                  left: 0, width: '40%',
                }}
              >
                {`${!dictionnaryAlign ? '< Left' : 'Right >'}`}
              </Button>
              <Button
                onClick={() => {
                  setSearchWord('');
                  setOpen(false);
                }}
                color="primary"
                style={{
                  right: 0, width: '40%',
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </div>
      )
      : (
        <Button
          onClick={() => setOpen(true)}
          color="primary"
          style={{
            display: 'flex', position: 'fixed', width: 80, height: 80, bottom: 20, right: !dictionnaryAlign ? 20 : null, left: dictionnaryAlign ? 20 : null, borderRadius: 50, zIndex: 1150,
          }}
        >
          <img style={{ width: '100%', height: '100%' }} src="../../img/dictionnary.png" title="Dictionnary" alt="DIC." />
        </Button>
      )
  );
  */
};

Dictionnary.propTypes = {
  dictionnary: PropTypes.bool.isRequired,
  dictionnaryAlign: PropTypes.bool.isRequired,
  changeDictionnaryAlign: PropTypes.func.isRequired,
};

function mapState(state) {
  const { dictionnary, dictionnaryAlign } = state.ui;
  return {
    dictionnary, dictionnaryAlign,
  };
}

const actionCreator = {
  changeDictionnaryAlign: uiActions.changeDictionnaryAlign,
};

export default connect(mapState, actionCreator)(Dictionnary);
