const userConstants = {

  GET_USER_REQUEST: 'USERS_GET_USER_REQUEST',
  GET_USER_SUCCESS: 'USERS_GET_USER_SUCCESS',
  GET_USER_FAILURE: 'USERS_GET_USER_FAILURE',

  CREATE_USER_REQUEST: 'USERS_CREATE_USER_REQUEST',
  CREATE_USER_SUCCESS: 'USERS_CREATE_USER_SUCCESS',
  CREATE_USER_FAILURE: 'USERS_CREATE_USER_FAILURE',

  UPDATE_USER_REQUEST: 'USERS_UPDATE_USER_REQUEST',
  UPDATE_USER_SUCCESS: 'USERS_UPDATE_USER_SUCCESS',
  UPDATE_USER_FAILURE: 'USERS_UPDATE_USER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  CONFIRM_REQUEST: 'USERS_CONFIRM_REQUEST',
  CONFIRM_SUCCESS: 'USERS_CONFIRM_SUCCESS',
  CONFIRM_FAILURE: 'USERS_CONFIRM_FAILURE',

  CLEAR_LOGIN: 'USERS_CLEAR_LOGIN',

  SET_AZURE_TOKEN: 'USERS_SET_AZURE_TOKEN',

  REQUEST_NEW_PASSWORD: 'USERS_REQUEST_NEW_PASSWORD',
  SUCCESS_NEW_PASSWORD: 'USERS_SUCCESS_NEW_PASSWORD',
  FAILURE_NEW_PASSWORD: 'USERS_FAILURE_NEW_PASSWORD',

  LOGIN_REFRESH_REQUEST: 'USERS_LOGIN_REFRESH_REQUEST',
  LOGIN_REFRESH_SUCCESS: 'USERS_LOGIN_REFRESH_SUCCESS',
  LOGIN_REFRESH_FAILURE: 'USERS_LOGIN_REFRESH_FAILURE',

  SET_TEST: 'USERS_SET_TEST',
  TEST_DONE: 'USERS_TEST_DONE',
};

export default userConstants;
