const startClosingPlatform = '2024-02-03';
const endClosingPlatform = '2024-02-07';
const startSatisfaction = '2023-01-01';
const endSatisfaction = '2023-12-31';

const dev = {
  PLATFORM: 'prod',
  startSatisfaction,
  endSatisfaction,
  START_CLOSING_PLATFORM: startClosingPlatform,
  END_CLOSING_PLATFORM: endClosingPlatform,
  primaryColor: '#1bb1e4',
  darkColor: '#137ea4',
  API_URL: 'http://localhost:3000',
  AZURE_CLIENT_ID: 'e673f229-73b7-40fe-a951-4a60bb6be089',
  OBJECT_URL: 'https://cdn.englishworld-learning.fr',
  LRS_ENDPOINT: 'https://lrs.englishworld-learning.fr/data/xAPI/&auth=Basic%20NzczNDI5MmVhYTdhN2IzM2Y1MzBiYTMzMDRkNzQwODU4YjIxZWViNzpiNzhlMGFmNTg3NzI3NzlmMTczZTVhZGU2MjYxM2NlNjNkYzA3NmUw',
};

const prod = {
  PLATFORM: 'prod',
  startSatisfaction,
  primaryColor: '#1bb1e4',
  darkColor: '#137ea4',
  endSatisfaction,
  START_CLOSING_PLATFORM: startClosingPlatform,
  END_CLOSING_PLATFORM: endClosingPlatform,
  API_URL: 'https://api.englishworld-learning.fr',
  OBJECT_URL: 'https://cdn.englishworld-learning.fr',
  PUBLIC_URL: 'https://englishworld-learning.fr/',
  AZURE_CLIENT_ID: '578fd207-e20b-46cf-a1be-3188ed0cd51a',
  LRS_ENDPOINT: 'https://lrs.englishworld-learning.fr/data/xAPI/&auth=Basic%20NzczNDI5MmVhYTdhN2IzM2Y1MzBiYTMzMDRkNzQwODU4YjIxZWViNzpiNzhlMGFmNTg3NzI3NzlmMTczZTVhZGU2MjYxM2NlNjNkYzA3NmUw',
};

const config = process.env.NODE_ENV === 'development' ? dev : prod;

export default config;
