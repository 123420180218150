/* eslint-disable no-console */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-underscore-dangle */
/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable linebreak-style */
import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
// import Card from '@material-ui/core/Card';
import { connect } from 'react-redux';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import TotalTimeWidget from './components/TotalTimeWidget';
import TotalProgressWidget from './components/TotalQuizzesProgressWidget';
import TotalUnitProgressWidget from './components/TotalUnitProgressWidget';
import NotificationsWidget from './components/NotificationsWidget';
import userServices from '../../redux/services/userServices';
import moduleServices from '../../redux/services/moduleServices';
import ResumeWidget from './components/ResumeWidget';
import ExamsDates from './components/ExamsDates';
import SatisfactionDialog from './SatisfactionDialog';
import DashboardLoader from '../Loader/DashboardLoader';
import WelcomeTitle from './components/WelcomeTitle';
import FunFactWidget from './components/FunFactWidget';
import NoGroupAssociatedCard from './components/NoGroupAssociatedCard';
// import useTimeCounter from '../Elearning/components/content/useTimeCounter';
import uiActions from '../../redux/actions/uiActions';
import userActions from '../../redux/actions/userActions';
import { toulouseSchools } from '../../helpers/utils';
import placementTestServices from '../../redux/services/placementTestServices';
import config from '../../config';
import ZoomLinks from './components/ZoomLinks';
import companyServices from '../../redux/services/companyServices';
import Dictionnary from '../Dictionnary';

const useStyles = makeStyles((theme) => ({
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  secondHalfContainer: {
    marginTop: '15px',
    marginBottom: '0px',
    marginLeft: '0px',
    marginRight: '0px',
  },
}));

const closePlatform = new Date(config.START_CLOSING_PLATFORM);
const endPlatform = new Date(config.END_CLOSING_PLATFORM);
const today = new Date();
closePlatform.setHours(1);
endPlatform.setHours(23);
today.setHours(22);

const Dashboard = ({
  changePageTitle, isTest, requestingGroup, data, firstname, userId, groupId, logout, company, userData, group,
}) => {
  const classes = useStyles();
  const platformClosed = closePlatform.getTime() <= today.getTime() && endPlatform.getTime() >= today.getTime();
  const [notifications, setNotifications] = useState([]);
  const [globalResults, setGlobalResults] = useState([]);
  const [testDone, setTestDone] = useState(false);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    changePageTitle('Dashboard');
    if (userData) {
      placementTestServices.getTest(userData._id)
        .then((res) => {
          if (res && res.scores && res.answers.length >= 60) {
            setTestDone(true);
            companyServices.getCompany(company?._id)
              .then((_res) => {
                if (_res && _res.loginBlocked) {
                  logout();
                }
              })
              .catch((e) => console.log(e));
          }
          setLoading(false);
        });
    }
  }, [userData, company]);

  useEffect(() => {
    userServices.getNotificationByUser(userId, company._id, userData.semester)
      .then((res) => {
        const tmp = [];
        res.forEach((notif) => {
          if (notif.range === 'Student' || notif.range === 'Group') {
            tmp.push(notif);
          }
        });
        setNotifications(tmp);
      });
    moduleServices.globalResultsTest(userData._id, userData.email, groupId)
      .then((res) => {
        setGlobalResults(res);
      });
  }, [groupId]);

  // const isIdle = useTimeCounter(userId, groupId || null);

  // (isIdle || platformClosed) && logout();
  platformClosed && logout();

  if (loading) {
    return (<DashboardLoader />);
  }

  if (!testDone && (isTest || isTest === undefined) && !toulouseSchools.includes(userData.company._id) && (userData.placementtest === true || userData.placementtest === undefined)) {
    return (<Redirect to="/test" />);
  }
  return requestingGroup ? (
    <DashboardLoader />
  ) : (
    <Container maxWidth="lg" className={classes.container}>
      <SatisfactionDialog />
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <Dictionnary />
      <Grid container style={{ justifyContent: 'space-between' }} spacing={3}>
        <Grid item xs={12} md={12}>
          <WelcomeTitle name={firstname} />
        </Grid>
        { data.length > 0 ? (
          <>
            <Grid item xs={12} md={4} lg={4}>
              <ExamsDates />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <ResumeWidget />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <FunFactWidget />
            </Grid>
            {/* <Card style={{ width: '100%', margin: '12px' }}>
              <Container maxWidth="lg" className={classes.container}> */}
            {/* <Grid container style={{ justifyContent: 'space-between' }} spacing={3}> */}
            <Grid item xs={12} md={4} lg={4}>
              <TotalTimeWidget data={globalResults} />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TotalProgressWidget data={globalResults} />
            </Grid>
            <Grid item xs={12} md={4} lg={4}>
              <TotalUnitProgressWidget data={globalResults} />
            </Grid>
            {userData.semester === group.semester
            && (
            <Grid item xs={12} md={6} lg={6}>
              <ZoomLinks group={group} company={company} />
            </Grid>
            )}
            <Grid container direction="row" className={classes.secondHalfContainer} spacing={3}>
              {notifications && notifications.map((notification) => (
                <Grid item xs={12} md={12} lg={12}>
                  <NotificationsWidget notification={notification} />
                </Grid>
              ))}
            </Grid>
          </>
        ) : (
          <Grid item xs={12} md={6}>
            <NoGroupAssociatedCard />
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

Dashboard.propTypes = {
  changePageTitle: PropTypes.func.isRequired,
  logout: PropTypes.func.isRequired,
  isTest: PropTypes.bool.isRequired,
  requestingGroup: PropTypes.bool.isRequired,
  firstname: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
  userId: PropTypes.string.isRequired,
  groupId: PropTypes.string.isRequired,
  company: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  userData: PropTypes.any.isRequired,
};

function mapState(state) {
  const { isTest, authData } = state.auth;
  const { requestingGroup, data } = state.group;
  const { currentGroup } = state.ui;
  const { _id } = authData;
  const { userData } = state.user;
  const { company } = userData;
  return {
    userData,
    company,
    isTest,
    requestingGroup: (requestingGroup || false),
    firstname: userData.firstname,
    data,
    userId: _id,
    groupId: data[currentGroup] ? data[currentGroup]._id : null,
    group: data[currentGroup],
  };
}

const actionCreators = {
  changePageTitle: uiActions.changePageTitle,
  logout: userActions.logout,
  getUser: userActions.getUser,
};

export default connect(mapState, actionCreators)(Dashboard);
