/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import {
  apiClient,
  handleResponse,
} from './axios';

async function uploadFile(data) {
  try {
    const res = await apiClient.post('/upload', data);
    return res.data.message;
  } catch (error) {
    return error.response.data;
  }
}

// company name + group name in body
async function checkFile(data) {
  try {
    const res = await apiClient.post('/getfile', data);
    return res;
  } catch (error) {
    return error.response.data;
  }
}
// company name + group name in body
async function deleteGoogleDriveFile(fileId) {
  try {
    const res = await apiClient.get(`/file/delete/${fileId}`);
    return res;
  } catch (error) {
    return error.response.data;
  }
}

const fileServices = {
  uploadFile,
  checkFile,
  deleteGoogleDriveFile,
};

export default fileServices;
