/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import PauseCircleOutlineIcon from '@material-ui/icons/PauseCircleOutline';
import { Button, Tooltip } from '@material-ui/core';
import config from '../../../../../../../config';

const SoundPlayer = ({ filename, audioAttribution }) => {
  const [audio, setAudio] = useState(null);
  const [audioCount, setAudioCount] = useState(0);
  const [isPlaying, setIsPlaying] = useState(false);

  const toggle = () => setIsPlaying(!isPlaying);

  useEffect(() => {
    audio && (isPlaying ? audio.play() : audio.pause());
  }, [isPlaying]);

  useEffect(() => {
    setAudio(new Audio(`${config.OBJECT_URL}/placementTest/audio/${filename}`));
    setIsPlaying(false);
    setAudioCount(0);
    audio && audio.pause();
    return () => {
      setIsPlaying(false);
      audio && audio.removeEventListener('ended', () => setIsPlaying(false));
    };
  }, [filename]);

  useEffect(() => {
    audio && (audio.addEventListener('ended', () => {
      setIsPlaying(false);
      setIsPlaying(false);
      setAudioCount(audioCount + 1);
    }));
    return () => {
      audio && audio.removeEventListener('ended', () => setIsPlaying(false));
    };
  }, [isPlaying, toggle]);

  return (
    <Tooltip title={audioAttribution}>
      <Button
        variant="contained"
        color="primary"
        onClick={toggle}
        style={{ padding: 10 }}
        disabled={audioCount === 2}
        startIcon={isPlaying ? <PauseCircleOutlineIcon /> : <PlayCircleOutlineIcon />}
      >
        {`${isPlaying ? 'Pause' : 'Play'} sound (${2 - audioCount})`}
      </Button>
    </Tooltip>
  );
};

SoundPlayer.defaultProps = {
  filename: '',
  audioAttribution: '',
};

SoundPlayer.propTypes = {
  filename: PropTypes.string,
  audioAttribution: PropTypes.string,
};

export default SoundPlayer;
