/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import {
  Typography,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  IconButton,
  Chip,
} from '@material-ui/core';
import config from '../../../config';

const useStyles = makeStyles({
  root: {
    maxWidth: 400,
    height: 250,
  },
  media: {
    height: 190,
  },
  subtitle: {
    fontSize: '0.8em',
  },
  tags: {
    margin: '1em 0 1em 0',
  },
  tag: {
    margin: '5px',
  },
  pdfButton: {
    position: 'absolute',
    zIndex: 20,
    top: '18vh',
    right: '15px',
  },
});

const VideoCard = ({
  // eslint-disable-next-line no-unused-vars
  title, filename, pdf, setUrl, credits, thumbnail, tags,
}) => {
  const classes = useStyles();

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Card style={{ position: 'relative' }}>
        {pdf && (
        <IconButton
          color="primary"
          aria-label="Download pdf"
          className={classes.pdfButton}
          component="a"
          target="_blank"
          href={`${config.OBJECT_URL}/resources/pdf/${pdf}`}
        >
          <PictureAsPdfIcon />
        </IconButton>
        )}
        <CardActionArea
          onClick={() => { setUrl(`${config.OBJECT_URL}/resources/video/${filename}`, filename); }}
          target="_blank"
          style={{ alignItems: 'center', minHeight: '7em' }}
        >
          <CardMedia
            className={classes.media}
            image={`${config.OBJECT_URL}/thumbnail/${thumbnail}`}
            title={title}
          />
          <CardContent>
            <Grid container direction="row">
              <Grid item md={10}>
                <Typography variant="body1">
                  {title}
                </Typography>
              </Grid>
              <Grid item md={2} />
              <Grid item md={12} className={classes.tags}>
                {tags.map((tag) => (
                  <Chip
                    color="primary"
                    label={tag}
                    className={classes.tag}
                  />
                ))}
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

VideoCard.defaultProps = {
  pdf: null,
};

VideoCard.propTypes = {
  title: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  pdf: PropTypes.string,
  setUrl: PropTypes.func.isRequired,
  credits: PropTypes.string.isRequired,
  tags: PropTypes.array.isRequired,
};

export default VideoCard;
