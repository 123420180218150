import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid,
  Typography,
  Card,
  CardMedia,
  CardContent,
  CardActionArea,
} from '@material-ui/core';

const useStyles = makeStyles({
  imgCard: {
    height: 0,
    paddingTop: '56.25%', // 16:9,
    marginTop: '30',
  },
  largemargin: {
    marginTop: '0em',
    marginRight: '1em',
    marginBottom: '2em',
    marginLeft: '0em',
  },
  mobilemargin: {
    marginBottom: '2em',
  },
});

const ThemeCard = (props) => {
  const { name, img } = props;
  const classes = useStyles();

  return (
    <Grid item lg={3} md={6} sm={12} xs={12}>
      <Card className={classes.largemargin}>
        <CardActionArea
          component={Link}
          to={{ pathname: `/learning-links/${name}` }}
        >
          <CardMedia
            image={img}
            className={classes.imgCard}
            alt={`${name} png`}
          />
          <CardContent>
            <Typography variant="h6">
              { name }
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

ThemeCard.propTypes = {
  name: PropTypes.string,
  img: PropTypes.string,
};

ThemeCard.defaultProps = {
  name: 'Default name',
  img: 'default.png',
};

export default ThemeCard;
