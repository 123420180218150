import {
  apiClient,
  handleResponse,
} from './axios';

/**
 * Get all themes in db.
 */
async function getThemes() {
  const response = await apiClient.get('/theme');
  return handleResponse(response);
}

/**
 * Get all topics of the specified theme name.
 * @param {String} theme
 */
async function getTopicsByTheme(theme) {
  const response = await apiClient.get(`/topic/theme/name/${theme}`);
  return handleResponse(response);
}

/**
 * Get all links of the specified topic name.
 * @param {String} topic
 */
async function getLinksByTopic(topic) {
  const response = await apiClient.get(`/link/topic/name/${topic}`);
  return handleResponse(response);
}

/**
 * Get all links of the specified theme name.
 * @param {String} theme
 */
async function getLinksByTheme(theme) {
  const response = await apiClient.get(`/link/theme/name/${theme}`);
  return handleResponse(response);
}

export default {
  getThemes,
  getTopicsByTheme,
  getLinksByTopic,
  getLinksByTheme,
};
