import React from 'react';
import PropTypes from 'prop-types';
import {
  Typography,
  Grid,
  Card,
  CardActionArea,
  CardContent,
} from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';

const LinkItem = (props) => {
  const { name, url } = props;

  return (
    <Grid item lg={6} md={12} sm={12} xs={12}>
      <Card className="card-ui" style={{ margin: '0em 1em 2em 0em' }}>
        <CardActionArea
          component="a"
          href={url}
          target="_blank"
          rel="no-opener no-referrer"
        >
          <CardContent>
            <Grid container alignItems="center">
              <Grid item lg={1} md={1} sm={1} xs={1}>
                <LinkIcon color="secondary" fontSize="large" />
              </Grid>
              <Grid item lg={11} md={11} sm={11} xs={11}>
                <Typography variant="h6" style={{ marginLeft: '1em' }}>
                  {name}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

LinkItem.propTypes = {
  name: PropTypes.string,
  url: PropTypes.string,
};

LinkItem.defaultProps = {
  name: 'Default name',
  url: 'default',
};

export default LinkItem;
