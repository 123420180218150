/* eslint-disable no-unused-expressions */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { Grid, Container, makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moduleActions from '../../../../redux/actions/moduleActions';
import ModuleCard from './ModuleCard';
import uiActions from '../../../../redux/actions/uiActions';
import userActions from '../../../../redux/actions/userActions';
import DashboardLoader from '../../../Loader/DashboardLoader';
// import useTimeCounter from './useTimeCounter';

const useStyles = makeStyles({
  paddingweb: {
    padding: '1em 0 10em 0',
  },
  paddingmobile: {
    padding: '1em',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
});

const Courses = ({
  getAllModules,
  data,
  coursesRequested,
  requestingCourses,
  currentGroup,
  changePageTitle,
  group,
  // userId,
  // groupId,
  // logout,
}) => {
  const [modules, setModules] = useState([]);
  const classes = useStyles();
  const { innerWidth: width } = window;
  const isSmallScreen = width < 450;

  useEffect(() => {
    getAllModules(group.data[currentGroup]._id);
    changePageTitle('E-Learning | Content');
  }, [currentGroup]);

  useEffect(() => {
    if (data) {
      if (data.length === 0) { return; }
      let tab = data[0].modules;
      tab = tab.sort((a, b) => {
        if (a.name < b.name) { return -1; }
        if (a.name > b.name) { return 1; }
        return 0;
      });
      setModules(tab);
    }
  }, [data]);

  // const isIdle = useTimeCounter(userId, groupId || null);

  // isIdle && logout();

  return requestingCourses ? (<DashboardLoader />) : (
    coursesRequested ? (
      <Container className={!isSmallScreen ? classes.paddingweb : classes.paddingmobile}>
        <Grid spacing={2} container direction="row">
          {data.length !== 0 && modules.length !== 0 && modules.map((module) => (
            <ModuleCard
              id={module._id}
              title={module.name}
              isRise={module.isRise}
              objectives={module.objectives}
              thumbnail={module.thumbnail}
              key={module._id}
            />
          ))}
        </Grid>
      </Container>
    ) : (
      <p>Nothing to display here</p>
    ));
};

Courses.propTypes = {
  getAllModules: PropTypes.func.isRequired,
  // logout: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  coursesRequested: PropTypes.bool.isRequired,
  requestingCourses: PropTypes.bool.isRequired,
  currentGroup: PropTypes.number.isRequired,
  changePageTitle: PropTypes.func.isRequired,
  group: PropTypes.object.isRequired,
  // groupId: PropTypes.string.isRequired,
  // userId: PropTypes.string.isRequired,
};

const mapState = (state) => {
  const { group } = state;
  const { data, coursesRequested, requestingCourses } = state.module;
  const { currentGroup } = state.ui;
  const { userData } = state.user;
  const userId = userData._id;
  return {
    group,
    data,
    coursesRequested,
    requestingCourses,
    currentGroup,
    userId,
    // groupId: data[currentGroup]._id ? data[currentGroup]._id : null,
  };
};

const actionCreators = {
  getAllModules: moduleActions.getAll,
  changePageTitle: uiActions.changePageTitle,
  logout: userActions.logout,
};

export default connect(mapState, actionCreators)(Courses);
