/* eslint-disable react/prop-types */
/* eslint-disable react/require-default-props */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import {
  Box, Divider, Snackbar, Tab, Tabs, Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import UpcomingWebinaire from './UpcomingWebinaire';
import OldWebinaire from './OldWebinaire';
import uiActions from '../../redux/actions/uiActions';
import RegisteredWebinaire from './RegisteredWebinaire';

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const Webinaire = ({ userData }) => {
  const [value, setValue] = useState(0);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [successSnackBar, setSuccessSnackBar] = useState(null);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'error'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <Snackbar open={successSnackBar !== null} onClose={() => { setSuccessSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'left' }}>
        <Alert severity={successSnackBar !== null ? successSnackBar.severity : 'success'} variant="filled" onClose={() => { setSuccessSnackBar(null); }}>
          {`${successSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <Box sx={{
        flexGrow: 1,
        bgcolor: 'background.paper',
        display: 'flex',
        minHeight: '85vh',
        height: '100%',
        width: '100%',
      }}
      >
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
          orientation="vertical"
          style={{
            minWidth: '200px',
            paddingTop: '20px',
          }}
        >
          <Tab label="Webinaires à venir" {...a11yProps(0)} />
          <Tab label="Replays webinaires" {...a11yProps(1)} />
          <Tab label="Mes webinaires" {...a11yProps(2)} />
        </Tabs>
        <Divider orientation="vertical" style={{ height: '100%' }} />
        <TabPanel value={value} index={0} style={{ width: '100%' }}>
          <UpcomingWebinaire
            userData={userData}
            setErrorSnackBar={setErrorSnackBar}
            setSuccessSnackBar={setSuccessSnackBar}
          />
        </TabPanel>
        <TabPanel value={value} index={1} style={{ width: '100%' }}>
          <OldWebinaire setErrorSnackBar={setErrorSnackBar} />
        </TabPanel>
        <TabPanel value={value} index={2} style={{ width: '100%' }}>
          <RegisteredWebinaire
            userData={userData}
            setErrorSnackBar={setErrorSnackBar}
            setSuccessSnackBar={setSuccessSnackBar}
          />
        </TabPanel>
      </Box>
    </>
  );
};

function mapState(state) {
  const { userData } = state.user;
  return {
    userData,
  };
}

const actionCreators = {
  changePageTitle: uiActions.changePageTitle,
};

export default connect(mapState, actionCreators)(Webinaire);
