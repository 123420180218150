import moduleConstants from '../constants/moduleConstants';

const modulesData = JSON.parse(localStorage.getItem('modules'));

const initialState = {
  requestingCourses: false,
  coursesRequested: false,
  data: modulesData || [],
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case moduleConstants.GETALL_REQUEST:
      return {
        ...state,
        requestingCourses: true,
        coursesRequested: false,
      };
    case moduleConstants.GETALL_SUCCESS:
      return {
        ...state,
        data: action.module,
        requestingCourses: false,
        coursesRequested: true,
      };
    case moduleConstants.SELECT_MODULE:
      return {
        ...state,
        selectedModule: action.selectedModule,
      };
    case moduleConstants.SELECT_UNIT:
      return {
        ...state,
        selectedUnit: action.selectedUnit,
      };
    case moduleConstants.GETALL_FAILURE:
      return {};

    case moduleConstants.UNLOAD_MODULES:
      return {};
    default:
      return state;
  }
}
