import {
  apiClient,
} from './axios';

async function getFiles(folder, companyName, subfolder) {
  try {
    const res = await apiClient.get(`/googlestorage/files/${folder}/${companyName}/${subfolder}`);
    return res;
  } catch (error) {
    return error;
  }
}

async function downloadFile(filename) {
  try {
    const res = await apiClient.post('/googlestorage/download', { filename });
    return res;
  } catch (error) {
    return error;
  }
}

const googleStorageServices = {
  getFiles,
  downloadFile,
};

export default googleStorageServices;
