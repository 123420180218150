/* eslint-disable max-len */
/* eslint-disable no-lonely-if */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import examType1Actions from '../../../../../../../redux/actions/examType1Actions';
import { shuffleArray } from '../../../../../../../helpers/utils';
import Word from './Word';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: '2em',
    textAlign: 'center',
    padding: '1em 1em 1em 1.5em',
    backgroundColor: '#eee',
    borderRadius: '8px',
    marginBottom: '2em',
    width: '98%',
  },
  drag: {
    height: '3em',
    border: '1px solid #ccc',
    borderRadius: '8px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    margin: '0.1em',
    backgroundColor: '#fff',
  },
  drop: {
    display: 'flex',
    flexDirection: 'row',
    minWidth: '100%',
  },
}));

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const WordList = React.memo(({ words }) => words.map((word, index) => (
  <Word word={word} index={index} key={word.id} />
)));

const DndAnswers = ({
  answers, currentAnswer, setCurrentAnswer, questionId, multiQuestionId,
}) => {
  const classes = useStyles();
  const [words, setWords] = useState([]);

  useEffect(() => {
    const tab = answers[0].text.split(' ');
    shuffleArray(tab);
    const wordsWithIndex = [];
    tab.map((word, i) => wordsWithIndex.push({
      id: `id-${i}`,
      content: word,
    }));
    setWords(wordsWithIndex);
  }, [questionId]);

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    if (result.destination.index === result.source.index) {
      return;
    }
    const newOrder = reorder(
      words,
      result.source.index,
      result.destination.index,
    );
    const newSentence = [];
    newOrder.forEach((elem) => newSentence.push(elem.content));
    const flatNewSentence = newSentence.join(' ');
    if (currentAnswer[multiQuestionId]) {
      const newAnswer = [...currentAnswer];
      if (flatNewSentence === answers[0].text) {
        newAnswer[multiQuestionId].answerIndex = [0];
        setCurrentAnswer(newAnswer);
      } else {
        newAnswer[multiQuestionId].answerIndex = [1];
        setCurrentAnswer(newAnswer);
      }
    } else {
      // eslint-disable-next-line max-len
      if (flatNewSentence === answers[0].text) {
        setCurrentAnswer([{ question: questionId, answerIndex: [0] }]);
      } else {
        setCurrentAnswer([{ question: questionId, answerIndex: [1] }]);
      }
    }
    setWords(newOrder);
  };

  return (
    <Grid container direction="row" className={classes.root}>
      <Grid item md="12">
        <DragDropContext className={classes.drag} style={{ fontSize: 19, lineHeight: 1.5 }} onDragEnd={onDragEnd}>
          <Droppable className={classes.drop} droppableId="list" direction="horizontal">
            {(provided) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <div ref={provided.innerRef} {...provided.droppableProps}>
                <WordList words={words} />
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    </Grid>
  );
};

DndAnswers.propTypes = {
  answers: PropTypes.array.isRequired,
  questionId: PropTypes.string.isRequired,
  currentAnswer: PropTypes.array.isRequired,
  setCurrentAnswer: PropTypes.func.isRequired,
  multiQuestionId: PropTypes.number.isRequired,
};

const actionCreators = {
  setCurrentAnswer: examType1Actions.setCurrentAnswer,
};

export default connect(null, actionCreators)(DndAnswers);
