import uiConstants from '../constants/uiConstants';

const changePageTitle = (title) => ({
  type: uiConstants.CHANGE_PAGE_TITLE,
  title,
});

const changeDarkMode = () => (dispatch, getState) => {
  const state = getState();
  localStorage.setItem('darkMode', !state.ui.darkMode);
  dispatch({
    type: uiConstants.CHANGE_DARK_MODE,
    darkMode: !state.ui.darkMode,
  });
};

const changeDictionnary = () => (dispatch, getState) => {
  const state = getState();
  localStorage.setItem('dictionnary', !state.ui.dictionnary);
  dispatch({
    type: uiConstants.CHANGE_DICTIONNARY,
    dictionnary: !state.ui.dictionnary,
  });
};

const changeDictionnaryAlign = () => (dispatch, getState) => {
  const state = getState();
  localStorage.setItem('dictionnaryAlign', !state.ui.dictionnaryAlign);
  dispatch({
    type: uiConstants.CHANGE_DICTIONNARY_ALIGN,
    dictionnaryAlign: !state.ui.dictionnaryAlign,
  });
};

const setCurrentGroup = (group) => (dispatch) => {
  localStorage.setItem('currentGroup', group);
  sessionStorage.setItem('currentGroup', group);
  dispatch({
    type: uiConstants.SET_CURRENT_GROUP,
    group,
  });
};

const uiActions = {
  changePageTitle,
  changeDarkMode,
  changeDictionnary,
  changeDictionnaryAlign,
  setCurrentGroup,
};

export default uiActions;
