/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Grid } from '@material-ui/core';
import DisplayLinks from './DisplayLinks';
import TopicLoader from './TopicLoader';
import TopicCard from './TopicCard';
import learningLinksActions from '../../../redux/actions/learningLinksActions';
import GoBackButton from './GoBackButton';

const DisplayTopics = ({ learningLinks, getTopics, location }) => {
  // Get theme name from the url.
  const theme = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);

  useEffect(() => {
    getTopics(theme);
  }, []);

  const displayContent = () => {
    if (learningLinks.requestingTopics) {
      return (
        <TopicLoader />
      );
    }
    if (learningLinks.topics.length !== 0) {
      return learningLinks.topics.map((topic) => (
        <TopicCard
          key={topic._id}
          name={topic.name}
          theme={theme}
        />
      ));
    }

    return (
      <DisplayLinks theme={theme} />
    );
  };

  return (
    <div>
      <h3 className="subtitle">
        <GoBackButton />
        {learningLinks.topics.length === 0 ? 'Links' : 'Topics'}
      </h3>
      <Grid container>
        {displayContent()}
      </Grid>
    </div>
  );
};

DisplayTopics.propTypes = {
  learningLinks: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  getTopics: PropTypes.func.isRequired,
};

function mapState(state) {
  const { learningLinks } = state;
  return { learningLinks };
}

const actionCreators = {
  getTopics: learningLinksActions.getTopicsByTheme,
  getSubcategory: learningLinksActions.getSubcategoriesByTopic,
};

const connectedDisplayTopics = connect(mapState, actionCreators)(DisplayTopics);
export default connectedDisplayTopics;
