/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PropTypes from 'prop-types';
import {
  Button,
  makeStyles,
  InputAdornment,
  OutlinedInput,
  InputLabel,
  FormControl,
  IconButton,
  Grid,
  FormHelperText,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import userServices from '../../../redux/services/userServices';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
}));

const isValidPassword = (password) => {
  // Minimum eight characters, at least one letter and one number
  const regex = new RegExp('^(?=.*\\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$');
  return regex.test(password);
};

const NewPasswordForm = ({ token }) => {
  const classes = useStyles();

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');
  const [showPass1, setShowPass1] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [creatingNewPassword, setCreatingNewPassword] = useState(false);
  const [newPasswordCreated, setNewPasswordCreated] = useState(false);

  const changeShowPass1 = () => {
    if (showPass1 === true) setShowPass1(false);
    else setShowPass1(true);
  };

  const changeShowPass2 = () => {
    if (showPass2 === true) setShowPass2(false);
    else setShowPass2(true);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleClick = (e) => {
    e.preventDefault();
    setCreatingNewPassword(true);
    const newPassword = {
      password: password1,
      token,
    };
    userServices.sendNewPassword(newPassword)
      .then(() => {
        toast.success('Votre mot de passe a été modifié avec succès');
        setCreatingNewPassword(false);
        setNewPasswordCreated(true);
      })
      .catch(() => {
        toast.error('Une erreur est survenue lors de la modification de votre mot de passe');
        setCreatingNewPassword(false);
        setNewPasswordCreated(false);
      });
  };

  return (
    <form className={classes.form} noValidate>
      <Grid item>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <InputLabel htmlFor="password">Mot de passe</InputLabel>
          <OutlinedInput
            error={password1 && !isValidPassword(password1)}
            type={showPass1 ? 'text' : 'password'}
            margin="normal"
            required
            name="password1"
            label="Mot de passe"
            id="password1"
            autoComplete="new-password"
            value={password1}
            onChange={(e) => setPassword1(e.target.value)}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={changeShowPass1}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPass1 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )}
          />
          {password1 && !isValidPassword(password1) && (
            <FormHelperText error id="password-error">
              Merci de créer un mot de passe avec au moins 8 caractères,
              une majuscule et un chiffre.
            </FormHelperText>
          )}
        </FormControl>
      </Grid>
      <Grid item style={{ marginTop: '3%' }}>
        <FormControl
          variant="outlined"
          fullWidth
        >
          <InputLabel htmlFor="password">Confirmer mot de passe</InputLabel>
          <OutlinedInput
            error={!(password1 === password2)}
            margin="normal"
            type={showPass2 ? 'text' : 'password'}
            required
            name="password2"
            label="Confirmer mot de passe"
            id="password2"
            autoComplete="new-password"
            value={password2}
            onChange={(e) => setPassword2(e.target.value)}
            endAdornment={(
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={changeShowPass2}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPass2 ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            )}
          />
        </FormControl>
      </Grid>
      <Button
        type="submit"
        fullWidth
        variant="contained"
        color="primary"
        onClick={(e) => handleClick(e)}
        style={{ marginTop: '3%' }}
        disabled={
          !((password1 === password2) && isValidPassword(password1))
          || creatingNewPassword
          || newPasswordCreated
        }
      >
        {creatingNewPassword ? 'Création en cours' : (newPasswordCreated ? 'Votre mot de passe a été modifié' : 'Créer un nouveau mot de passe')}
      </Button>
      <ToastContainer
        position="top-left"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </form>
  );
};

NewPasswordForm.propTypes = {
  token: PropTypes.string.isRequired,
};

export default NewPasswordForm;
