/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Card, CardActionArea, Typography, CardMedia, Avatar, Grid, Snackbar,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import ResumeWidgetLoader from '../../Loader/ResumeWidgetLoader';
import moduleServices from '../../../redux/services/moduleServices';
import config from '../../../config';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  title: {
    padding: '1em',
  },
  center: {
    textAlign: 'center',
    margin: 'auto',
    width: '50%',
  },
  imgCard: {
    height: '20vh',
  },
  cardAction: {
    padding: '1em',
    width: '100%',
    height: '100%',
  },
  imgCardStart: {
    height: '20vh',
  },
});

const ResumeWidget = ({ _id, data, currentGroup }) => {
  const classes = useStyles();
  // eslint-disable-next-line no-unused-vars
  const [lastUnitLoading, setLastUnitLoading] = useState(true);
  const [pathname, setPathname] = useState('');
  const [unit, setUnit] = useState(null);
  const [module, setModule] = useState(null);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [unitIndex, setunitIndex] = useState(0);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled) {
      if (data[currentGroup]) {
        moduleServices.getLastActivity(_id, data[currentGroup]._id)
          .then(
            (res) => {
              if (res.unit === null || res.unit === undefined) {
                setLastUnitLoading(false);
                setPathname('/elearning/course');
              } else {
                setModule(res.module);
                setUnit(res.unit);
                setLastUnitLoading(false);
                setPathname(`/elearning/course/${res.moduleId}`);
                setunitIndex(res.index);
              }
            },
            () => {
              if (!isCancelled) {
                setLastUnitLoading(false);
                setPathname('/elearning/course');
              }
            },
          )
          .catch(() => {
            setErrorSnackBar({ code: 1301, message: 'Missing last activity.', severity: 'warning' });
          });
      }
    }
    return () => {
      isCancelled = true;
    };
  }, [currentGroup, data]);

  return !lastUnitLoading ? (
    unit ? (
      <Card>
        <CardActionArea
          component={Link}
          to={{
            pathname,
            state: {
              title: module.name,
              selectedUnit: Number(unitIndex),
            },
          }}
          className={classes.cardAction}
        >
          <CardMedia
            className={classes.imgCard}
            image={`${config.OBJECT_URL}/thumbnail/${module.thumbnail}`}
            title={`${module.name} thumbnail`}
            component={Link}
            to={{
              pathname,
              state: {
                title: module.name,
                selectedUnit: Number(unitIndex),
              },
            }}
          />
          <Grid container direction="row">
            <Grid item md={2}>
              <Avatar alt={unit.name} aria-label="unit icon" src={`${config.OBJECT_URL}/thumbnail/${unit.thumbnail}`} className={classes.avatar} />
            </Grid>
            <Grid item md={10}>
              <Typography
                variant="body1"
                style={{ fontWeight: '900', marginTop: '2em', textAlign: 'center' }}
              >
                CONTINUE
              </Typography>
              <Typography variant="subtitle1">{unit && unit.name}</Typography>
            </Grid>
          </Grid>
        </CardActionArea>
      </Card>
    ) : (
      <Card style={{ height: '100%' }}>
        <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
            {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
          </Alert>
        </Snackbar>
        <CardActionArea
          className={classes.cardAction}
          component={Link}
          to={{
            pathname,
          }}
        >
          <CardMedia
            image={`${config.OBJECT_URL}/thumbnail/start_course.svg`}
            title="start learning thumbnail"
            component={Link}
            to={{
              pathname,
            }}
            className={classes.imgCard}
          />
          <Typography
            variant="body1"
            style={{ fontWeight: '900', marginTop: '2em', textAlign: 'center' }}
          >
            Your course
          </Typography>
        </CardActionArea>
      </Card>
    )
  ) : <ResumeWidgetLoader />;
};

ResumeWidget.propTypes = {
  currentGroup: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  _id: PropTypes.string.isRequired,
};

function mapState(state) {
  const { data } = state.group;
  const { currentGroup } = state.ui;
  const { authData } = state.auth;
  const { _id } = authData;
  return {
    _id, data, currentGroup,
  };
}

export default connect(mapState, {})(ResumeWidget);
