import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  CardContent,
  CardActionArea,
  Typography,
} from '@material-ui/core';
import FolderIcon from '@material-ui/icons/Folder';

const TopicCard = (props) => {
  const { name, theme } = props;
  return (
    <Grid item lg={4} md={4} sm={12} xs={12}>
      <Card style={{ margin: '0em 1em 2em 0em' }}>
        <CardActionArea
          component={RouterLink}
          to={{ pathname: `/learning-links/${theme}/${name}` }}
        >
          <CardContent>
            <Grid container alignItems="center">
              <Grid item lg={2} md={3} sm={1} xs={1}>
                <FolderIcon color="secondary" fontSize="large" />
              </Grid>
              <Grid item lg={10} md={9} sm={11} xs={11}>
                <Typography variant="h6" style={{ marginLeft: '1em' }}>
                  { name }
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
  );
};

TopicCard.propTypes = {
  name: PropTypes.string,
  theme: PropTypes.string,
};

TopicCard.defaultProps = {
  name: 'Default',
  theme: 'Default',
};

export default TopicCard;
