/* eslint-disable no-shadow */
import learningLinksConstants from '../constants/learningLinksConstants';
import learningLinksServices from '../services/learningLinksServices';

/**
 * Action to get all the themes in db.
 */
function getAllThemes() {
  function request() {
    return {
      type: learningLinksConstants.GET_THEMES_REQUEST,
    };
  }

  function success(themes) {
    return {
      type: learningLinksConstants.GET_THEMES_SUCCESS,
      themes,
    };
  }

  function failure(error) {
    return {
      type: learningLinksConstants.GET_THEMES_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    learningLinksServices.getThemes()
      .then(
        (themes) => {
          dispatch(success(themes));
        },
        (error) => {
          dispatch(failure(error));
        },
      );
  };
}

/**
 * Action to get all topics belonging to the specified theme name.
 * @param {String} theme
 */
function getTopicsByTheme(theme) {
  function request(theme) {
    return {
      type: learningLinksConstants.GET_TOPICS_REQUEST,
      theme,
    };
  }

  function success(topics) {
    return {
      type: learningLinksConstants.GET_TOPICS_SUCCESS,
      topics,
    };
  }

  function failure(error) {
    return {
      type: learningLinksConstants.GET_TOPICS_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request(theme));
    learningLinksServices.getTopicsByTheme(theme)
      .then(
        (topics) => {
          dispatch(success(topics));
        },
        (error) => {
          dispatch(failure(error));
        },
      );
  };
}

/**
 * Action to get all links belonging to the specified topic name.
 * @param {String} topic
 */
function getLinksByTopic(topic) {
  function request(topic) {
    return {
      type: learningLinksConstants.GET_TOPICS_LINKS_REQUEST,
      topic,
    };
  }

  function success(topicLinks) {
    return {
      type: learningLinksConstants.GET_TOPICS_LINKS_SUCCESS,
      topicLinks,
    };
  }

  function failure(error) {
    return {
      type: learningLinksConstants.GET_TOPICS_LINKS_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request(topic));
    learningLinksServices.getLinksByTopic(topic)
      .then(
        (topicLinks) => {
          dispatch(success(topicLinks));
        },
        (error) => {
          dispatch(failure(error));
        },
      );
  };
}

/**
 * Action to get all links belonging to the specified theme name
 * @param {String} theme
 */
function getLinksByTheme(theme) {
  function request(theme) {
    return {
      type: learningLinksConstants.GET_LINKS_REQUEST,
      theme,
    };
  }

  function success(links) {
    return {
      type: learningLinksConstants.GET_LINKS_SUCCESS,
      links,
    };
  }

  function failure(error) {
    return {
      type: learningLinksConstants.GET_LINKS_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request(theme));
    learningLinksServices.getLinksByTheme(theme)
      .then(
        (links) => {
          dispatch(success(links));
        },
        (error) => {
          dispatch(failure(error));
        },
      );
  };
}

export default {
  getAllThemes,
  getTopicsByTheme,
  getLinksByTopic,
  getLinksByTheme,
};
