/* eslint-disable no-underscore-dangle */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Dialog, DialogTitle, Button, Typography, DialogContent,
} from '@material-ui/core';
import surveysServices from '../../redux/services/surveysServices';

const SatisfactionDialog = ({
  userId, semester, companyId,
}) => {
  const [open, setOpen] = useState(false);
  const [survey, setSurvey] = useState(null);

  useEffect(() => {
    surveysServices.getSurvey(userId, semester, companyId)
      .then((res) => {
        if (!res.usersDone || !res.usersDone.includes(userId)) {
          setSurvey(res);
          if (res._id) {
            setOpen(res !== null);
          }
        }
      });
  }, []);

  const surveyDone = () => {
    if (survey.usersDone && survey.usersDone.length > 0) {
      const tmp = survey.usersDone;
      tmp.push(userId);
      surveysServices.updateSurvey(survey._id, { usersDone: tmp });
    } else {
      surveysServices.updateSurvey(survey._id, { usersDone: [userId] });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
    >
      <DialogTitle style={{
        backgroundColor: 'rgb(63, 81, 181)', color: 'white',
      }}
      >
        <Typography style={{ fontWeight: 'bold', fontSize: 20 }}>
          Votre avis nous intéresse !
        </Typography>
      </DialogTitle>
      <DialogContent style={{
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end',
      }}
      >
        <Typography style={{ width: '90%', margin: '5%' }}>
          Merci de répondre à notre enquête de satisfaction.
        </Typography>
        <Button
          autoFocus
          onClick={() => { setOpen(false); surveyDone(); }}
          href={survey?.surveyLink}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            backgroundColor: 'rgb(63, 81, 181)', color: 'white', paddingLeft: 20, paddingRight: 20, marginBottom: 15, fontWeight: 'bold',
          }}
        >
          Je réponds à l'enquête
        </Button>
      </DialogContent>
    </Dialog>
  );
};

function mapState(state) {
  const { _id, semester, company } = state.user.userData;
  return {
    userId: _id,
    semester,
    companyId: company._id,
  };
}

SatisfactionDialog.propTypes = {
  userId: PropTypes.string.isRequired,
  companyId: PropTypes.string.isRequired,
  semester: PropTypes.number.isRequired,
};

export default connect(mapState, null)(SatisfactionDialog);
