/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react';
import {
  Typography,
  Grid,
  Button,
  makeStyles,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { Redirect } from 'react-router-dom';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import userActions from '../../../redux/actions/userActions';
import NewPasswordForm from './NewPasswordForm';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  header: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    textAlign: 'center',
    marginBottom: '3%',
    flexBasis: '0%',
    maxWidth: '100%',
  },
  title: {
    padding: '0.5em',
    fontWeight: 'bold',
    fontSize: 20,
  },
}));

const NewPassword = ({ open, tokenToCheck }) => {
  const classes = useStyles();

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [exit, setExit] = useState(false);
  const { innerWidth: width } = window;
  const isSmallScreen = width < 450;

  const handleClose = () => {
    setExit(true);
  };

  return exit ? (<Redirect to="/" />) : (
    <Dialog
      fullScreen={!isSmallScreen ? fullScreen : null}
      open={open}
      onClose={handleClose}
      aria-labelledby="responsive-dialog-title"
    >
      <Grid item md={12} xs={12} className={classes.header}>
        <Typography className={classes.title}>
          Nouveau Mot de passe
        </Typography>
      </Grid>
      <DialogContent>
        <Grid container style={{ marginRight: isSmallScreen ? null : '500px', display: isSmallScreen ? 'block' : 'flex' }}>
          <NewPasswordForm token={tokenToCheck} />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose} color="primary" style={{ marginRight: isSmallScreen ? '37%' : null }}>
          Fermer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

NewPassword.propTypes = {
  open: PropTypes.bool.isRequired,
  tokenToCheck: PropTypes.string.isRequired,
};

const actionCreators = {
  checkPasswordToken: userActions.checkPasswordToken,
};

export default connect(null, actionCreators)(NewPassword);
