/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable default-case */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import Question from './Question';
import Answers from './Answers';
import NextButton from './NextButton';
import Media from './Media';
import DescriptionDialog from './DescriptionDialog';

const useStyles = makeStyles(() => ({
  root: {
    margin: '1em 0',
  },
  media: {
    textAlign: 'center',
    margin: '1em 0 1em 0',
  },
  nextButton: {
    padding: '0 2em',
  },
  indexQuestion: {
    marginBottom: '30px',
    alignSelf: 'center',
  },
}));

const TestBody = ({
  testData, currentQuestion, multiQuestionId, descriptionRC, descriptionOC, descriptionKN,
}) => {
  const [open, setOpen] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    setOpen(true);
  }, [testData[currentQuestion].category]);

  return (
    <Grid
      container
      direction="row"
      className={classes.root}
      justifyContent="center"
    >
      <div className={classes.indexQuestion}>{`Question : ${currentQuestion + 1} / ${testData.length + 1}`}</div>
      {testData[currentQuestion].media.filename && (
        <Grid item xs={12} md={12} className={classes.media}>
          <Media
            // eslint-disable-next-line max-len
            audioAttribution={testData[currentQuestion].media.audioAttribution}
            filename={testData[currentQuestion].media.filename}
            type={testData[currentQuestion].media.type}
          />
        </Grid>
      )}
      <Grid item xs={12} md={12}>
        <Question
          question={testData[currentQuestion].name}
          type={testData[currentQuestion].type}
        />
      </Grid>
      <Grid item xs={12} md={12}>
        <Answers
          questionId={testData[currentQuestion]._id}
          question={testData[currentQuestion].name}
          answers={testData[currentQuestion].answers}
          type={testData[currentQuestion].type}
          multiQuestionId={multiQuestionId}
        />
      </Grid>
      <Grid item xs={12} md={12} className={classes.nextButton}>
        <NextButton className={classes.root} />
      </Grid>
      {
        (() => {
          switch (testData[currentQuestion].category) {
            case 'KN':
              return <DescriptionDialog title="KNOWLEDGE" description={descriptionKN} open={open} setOpen={setOpen} />;
            case 'RC':
              return <DescriptionDialog title="READING COMPREHENSION" description={descriptionRC} open={open} setOpen={setOpen} />;
            case 'OC':
              return <DescriptionDialog title="ORAL COMPREHENSION" description={descriptionOC} open={open} setOpen={setOpen} />;
          }
        })()
      }
    </Grid>
  );
};

TestBody.propTypes = {
  testData: PropTypes.array.isRequired,
  currentQuestion: PropTypes.number.isRequired,
  descriptionKN: PropTypes.string.isRequired,
  descriptionRC: PropTypes.string.isRequired,
  descriptionOC: PropTypes.string.isRequired,
};

TestBody.propTypes = {
  currentQuestion: PropTypes.number.isRequired,
  multiQuestionId: PropTypes.number.isRequired,
};

const actionCreators = {
};

function mapState(state) {
  const { currentQuestion, multiQuestionId } = state.placementTest;
  return {
    currentQuestion, multiQuestionId,
  };
}
export default connect(mapState, actionCreators)(TestBody);
