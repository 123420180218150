/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Grid, TextField,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  body: {
    width: '100%',
    height: '100%',
  },
  paddingBottom: {
    paddingBottom: '1.5em',
  },
  buttons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
    height: '100%',
    top: '4.5%',
  },
  button: {
    userSelect: 'none',
    color: '#06b1e4',
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    borderColor: '#A7A6A6',
    fontSize: '100%',
    width: '100%',
  },
  buttonbg: {
    border: 'none',
    backgroundColor: theme.palette.primary.main,
    borderColor: '#A7A6A6',
    fontSize: '100%',
    width: '100%',
  },
  selected: {
    color: theme.palette.primary.main,
    backgroundColor: '#06b1e4 !important',
  },
}));

const SearchField = ({ search, setSearch }) => {
  const classes = useStyles();

  return (
    <Grid
      container
      className={`${classes.body} ${classes.paddingBottom}`}
      justifyContent="center"
    >
      <TextField
        className={classes.body}
        id="glossary-search"
        label="Search..."
        variant="outlined"
        onChange={(e) => setSearch(e.target.value)}
        value={search}
      />
    </Grid>
  );
};

SearchField.propTypes = {
  search: PropTypes.string.isRequired,
  setSearch: PropTypes.func.isRequired,
};

export default SearchField;
