/* eslint-disable no-underscore-dangle */
import moduleConstants from '../constants/moduleConstants';
import moduleServices from '../services/moduleServices';

function getAll(groupId) {
  function request() {
    return {
      type: moduleConstants.GETALL_REQUEST,
    };
  }

  function success(module) {
    return {
      type: moduleConstants.GETALL_SUCCESS,
      module,
    };
  }

  function failure(error) {
    return {
      type: moduleConstants.GETALL_FAILURE,
      error,
    };
  }

  return (dispatch, getState) => {
    dispatch(request());
    moduleServices.getAll(getState().auth.authData._id, groupId)
      .then(
        (courses) => {
          localStorage.setItem('modules', JSON.stringify(courses));
          dispatch(success(courses));
        },
        (error) => {
          dispatch(failure(error.toString()));
        },
      );
  };
}

function selectModule(moduleId) {
  return (dispatch, getState) => {
    let selectedModule = {};
    const state = getState();
    if (!state.courses.coursesRequested) {
      dispatch(getAll());
    } else {
      selectedModule = state.courses.data[state.ui.currentGroup].modules.find(
        (mod) => mod._id === moduleId,
      );
    }
    dispatch({
      type: moduleConstants.SELECT_MODULE,
      selectedModule,
    });
  };
}
function selectUnit(unitId) {
  return (dispatch) => {
    dispatch({
      type: moduleConstants.SELECT_UNIT,
      selectedUnit: unitId,
    });
  };
}

export default {
  getAll,
  selectModule,
  selectUnit,
};
