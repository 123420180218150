/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import {
  Typography, Grid, Card, Divider, Snackbar,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import groupServices from '../../../redux/services/groupServices';
import companyServices from '../../../redux/services/companyServices';

const moment = require('moment');

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    minHeight: '250px',
  },
  semesterDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  examDataContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  semesterTitle: {
    fontSize: '22px',
  },
  semesterSubTitle: {
    fontSize: '15px',
  },
  cardHeader: {
    backgroundColor: theme.palette.primary.main,
    color: '#FFF',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  },
  cardBody: {
    width: '90%',
    height: '100%',
    margin: 'auto',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  smallPadding: {
    paddingBottom: 15,
    paddingTop: 15,
  },
  cardBodyWeb: {
    height: '80%',
  },
  center: {
    textAlign: 'center',
    margin: 'auto',
    width: '100%',
    height: '100%',
  },
  midContainer: {
    height: 'min-content',
  },
  title: {
    padding: '1em',
    textAlign: 'left',
    maxHeight: 50,
    fontWeight: 'bold',
  },
  boldTitle: {
    paddingRight: '1em',
    maxHeight: 50,
    fontWeight: 'bold',
  },
  actionArea: {
    width: '100%',
    height: '100%',
  },
  subTitle: {
    paddingBottom: '0.5em',
  },
}));

const FormatDate = (date, format) => {
  const formatedDate = date ? moment(date).format(format) : '';
  return formatedDate;
};

const getGap = (date1, date2, additionalTime) => {
  const dateDiff = new Date(date2) - new Date(date1);
  const diffMins = Math.round(dateDiff / 60 / 1000); // minutes
  let hoursDiff = Math.round((diffMins - (diffMins % 60)) / 60);
  const daysDiff = hoursDiff / 24;
  hoursDiff %= 24;
  return `${daysDiff >= 1 ? `${Math.round(daysDiff)} day${daysDiff > 1 ? 's' : ''} ` : ''}
  ${hoursDiff >= 1 ? `${hoursDiff}h` : ''}${diffMins % 60 < 10 && diffMins % 60 > 0 ? '0' : ''}
  ${diffMins % 60 > 0 ? `${diffMins % 60}min` : ''}
  ${additionalTime && additionalTime > 0 ? ` + ${additionalTime}min extra time` : ''}`;
};

const ExamsDates = ({ company, userData }) => {
  const classes = useStyles();
  const [semesterData, setSemesterData] = useState(null);
  const [examData, setExamData] = useState([]);
  const [groups, setGroups] = useState([]);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const { innerWidth: width } = window;
  const isSmallScreen = width < 450;

  useEffect(() => {
    const tmp = [];
    const today = new Date();
    today.setHours(1);
    for (const _group in groups) {
      const group = groups[_group];
      // eslint-disable-next-line max-len
      if (group.elearning && group.elearning.elearningOptions && group.level === 1 && (userData.semester === group.semester || (userData.catchUp && userData.catchUp.includes(group.semester)))) {
        group.elearning.elearningOptions.forEach((option) => {
          if (new Date(option.examBegin) > today) {
            tmp.push({ ...option, semester: group.semester });
          }
        });
      }
    }
    setExamData(tmp);
  }, [groups]);

  useEffect(async () => {
    const groupsDataByCompany = JSON.parse(localStorage.getItem('groupsDataByCompany'));
    const today = new Date();
    if (!groupsDataByCompany || today > new Date(groupsDataByCompany?.date)) {
      await groupServices.getGroupsDataByCompagny(company._id)
        .then((res) => {
          if (res.data) {
            setGroups(res.data);
            const date = new Date();
            date.setDate(date.getDate() + 1);
            localStorage.setItem('groupsDataByCompany', JSON.stringify({ date, data: res.data }));
          }
        })
        .catch(() => {
          setErrorSnackBar({ code: 1101, message: 'Missing groups data.', severity: 'error' });
        });
    } else {
      setGroups(groupsDataByCompany.data);
    }
  }, []);

  useEffect(() => {
    // const companyDataById = JSON.parse(localStorage.getItem('companyDataById'));
    const companyDataById = null;
    const today = new Date();
    if (!companyDataById || today > new Date(companyDataById?.date)) {
      companyServices.getCompanyDataById(company._id)
        .then((res) => {
          if (res.semesters && res.semesters.length > 0) {
            let actualSemester = null;
            let nextSemester = null;
            res.semesters.forEach((semester) => {
              if (semester.semester === userData.semester) {
                actualSemester = semester;
              } else if (semester.semester === userData.semester + 1) {
                nextSemester = semester;
              }
            });
            let resSemester = null;
            const date = new Date();
            if (!nextSemester) {
              resSemester = actualSemester;
            } else {
              const endDate = new Date(actualSemester.endDate);
              if (date.getTime() >= endDate.getTime()) {
                resSemester = nextSemester;
              }
            }
            setSemesterData(resSemester);
            date.setDate(date.getDate() + 1);
            localStorage.setItem('companyDataById', JSON.stringify({ date, data: resSemester }));
          }
        });
    } else {
      setSemesterData(companyDataById.data);
    }
  }, [company]);

  return (
    <Card container className={classes.root}>
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <Card
        className={classes.actionArea}
      >
        <Grid container direction="row" className={classes.center}>
          <Grid item md={12} className={classes.cardHeader}>
            <Typography
              variant="body1"
              className={classes.title}
            >
              {`${company.isCompany && company.isCompany === true ? 'Course' : 'Semester'} and exams dates`}
            </Typography>
          </Grid>
          <Grid container direction="column" className={[classes.cardBody, !isSmallScreen ? classes.cardBodyWeb : null, !examData || examData.length <= 2 ? classes.smallPadding : null]} style={{ display: 'flex', flexDirection: 'column' }}>
            <div className={classes.semesterDataContainer}>
              {
                semesterData !== null && semesterData
                  ? (
                    <>
                      <Typography className={classes.semesterTitle}>
                        {company.isCompany && company.isCompany === true ? 'Your course' : `Semester ${semesterData.semester},`}
                      </Typography>
                      <Typography className={classes.semesterSubTitle}>
                        {`from ${FormatDate(semesterData.beginDate, 'DD MMMM YYYY')} to ${FormatDate(semesterData.endDate, 'DD MMMM YYYY')} 23:00PM`}
                      </Typography>
                      {
                        semesterData.catchUpDate
                          ? (
                            <Typography className={classes.semesterSubTitle}>
                              {`Fin de rattrapages: ${FormatDate(semesterData.catchUpDate, 'DD MMMM YYYY')}`}
                            </Typography>
                          )
                          : null
                      }
                    </>
                  )
                  : (
                    <Typography>
                      Les dates de fin de semestre seront communiquées ultérieurement.
                    </Typography>
                  )
              }
            </div>
            {
              semesterData !== null && examData.length > 0
                ? <Divider style={{ margin: '5px' }} />
                : null
            }
            { examData.length > 0
              ? (
                <div className={classes.examDataContainer}>
                  <Typography className={classes.semesterTitle}>
                    Dates d'examens prévus :
                  </Typography>
                  {
                  (examData.sort((a, b) => (new Date(a.examBegin).getDate() > new Date(b.examBegin).getDate() ? 1 : -1)).splice(0, 3).map((exam) => (
                    <Typography className={classes.semesterSubTitle}>
                      {`S${exam.semester} ${exam.catchUp ? 'rattrap. ' : ''}- ${FormatDate(exam.examBegin, 'DD/MM/YYYY')} at ${FormatDate(exam.examBegin, 'HH:mm')} for ${getGap(exam.examBegin, exam.examEnd, userData.additionalTime)}`}
                    </Typography>
                  )))
                }
                </div>
              )
              : null}
          </Grid>
        </Grid>
      </Card>
    </Card>
  );
};

ExamsDates.propTypes = {
  company: PropTypes.object.isRequired,
  userData: PropTypes.object.isRequired,
};

function mapState(state) {
  const { userData } = state.user;
  const { company } = userData;

  return { company, userData };
}
export default connect(mapState, null)(ExamsDates);
