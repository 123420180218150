/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Button, Grid } from '@material-ui/core';
import placementTestActions from '../../../redux/actions/placementTestActions';
import { shuffleArray } from '../../../helpers/utils';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
    textTransform: 'none',
  },
  buttonRoot: {
    padding: '1em 2em',
  },
  resetButton: {
    textAlign: 'center',
    marginTop: '2em',
  },
}));

const FillBlankAnswers = ({
  // eslint-disable-next-line no-unused-vars
  answers, question, currentAnswer, setCurrentAnswer, questionId, multiQuestionId,
}) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const [shuffledAnswers, setShuffledAnswers] = useState([]);
  const [totalHoles, setTotalHoles] = useState(0);

  useEffect(() => {
    const shuffled = [];
    answers.map((answer, index) => shuffled.push({ ...answer, trueIndex: index }));
    shuffleArray(shuffled);
    setShuffledAnswers(shuffled);

    const splitted = question.split(/[[\]]/g);
    let holeCount = 0;
    splitted.forEach((item) => {
      if (item.substring(0, 5) === 'HOLE_') {
        holeCount += 1;
      }
    });
    setTotalHoles(holeCount);
  }, [questionId]);

  const handleAnswer = (index, trueIndex) => {
    if (currentAnswer[multiQuestionId]) {
      const newAnswer = [...currentAnswer];
      newAnswer[multiQuestionId].answerIndex.push(trueIndex);
      newAnswer[multiQuestionId].fakeIndex.push(index);
      setCurrentAnswer(newAnswer);
    } else {
      setCurrentAnswer([{ question: questionId, answerIndex: [trueIndex], fakeIndex: [index] }]);
    }
  };

  const resetAnswers = () => {
    const newAnswer = [...currentAnswer];
    delete newAnswer[multiQuestionId];
    setCurrentAnswer(newAnswer);
  };

  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item md={12} style={{ textAlign: 'center' }}>
        {
          shuffledAnswers.map((answer, index) => (
            <Button
              className={classes.button}
              key={answer.text}
              variant="outlined"
              color="primary"
              // eslint-disable-next-line max-len
              disabled={currentAnswer[multiQuestionId] && (currentAnswer[multiQuestionId].answerIndex.length >= totalHoles)}
              onClick={() => handleAnswer(index, answer.trueIndex)}
            >
              {answer.text}
            </Button>
          ))
        }
      </Grid>
      <Grid item md={12} className={classes.resetButton}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => resetAnswers()}
        >
          Reset answers
        </Button>
      </Grid>
    </Grid>
  );
};

FillBlankAnswers.propTypes = {
  answers: PropTypes.array.isRequired,
  question: PropTypes.string.isRequired,
  currentAnswer: PropTypes.array.isRequired,
  questionId: PropTypes.string.isRequired,
  setCurrentAnswer: PropTypes.func.isRequired,
  multiQuestionId: PropTypes.number.isRequired,
};

const actionCreators = {
  setCurrentAnswer: placementTestActions.setCurrentAnswer,
};

export default connect(null, actionCreators)(FillBlankAnswers);
