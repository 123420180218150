import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, Button,
} from '@material-ui/core';

const DescriptionDialog = ({
  description, title, open, setOpen,
}) => (
  <Dialog
    open={open}
    onClose={() => setOpen(false)}
  >
    <DialogTitle style={{ fontSize: 19, lineHeight: 1.5 }}>{title}</DialogTitle>
    <DialogContent style={{ fontSize: 19, lineHeight: 1.5 }}>description</DialogContent>
    <DialogContent style={{ fontSize: 19, lineHeight: 1.5 }}>{description}</DialogContent>
    <Button onClick={() => setOpen(false)} style={{ fontSize: 19, lineHeight: 1.5 }} autoFocus>
      J&apos;AI COMPRIS
    </Button>
  </Dialog>
);

DescriptionDialog.propTypes = {
  setOpen: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default DescriptionDialog;
