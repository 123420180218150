/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Container, Grid, Snackbar } from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Alert from '@material-ui/lab/Alert';
import ResultsPanel from './ResultsPanel';
import moduleActions from '../../../../redux/actions/moduleActions';
import moduleServices from '../../../../redux/services/moduleServices';
import GlobalResults from './GlobalResults';
import uiActions from '../../../../redux/actions/uiActions';
import userActions from '../../../../redux/actions/userActions';
import DashboardLoader from '../../../Loader/DashboardLoader';
import Dictionnary from '../../../Dictionnary';
// import useTimeCounter from '../content/useTimeCounter';

const useStyles = makeStyles(() => ({
  root: {
    marginBottom: '10em',
  },
}));

const Results = ({
  requestingCourses,
  currentGroup,
  userData,
  group,
  changePageTitle,
  // userId,
  // groupId,
  // logout,
}) => {
  changePageTitle('Results');
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [globalResults, setGlobalResults] = useState([]);

  useEffect(() => {
    setLoading(true);
    if (userData !== undefined && group.data[currentGroup]._id.length > 0) {
      moduleServices.globalResultsTest(userData._id, userData.email, group.data[currentGroup]._id)
        .then((results) => {
          setGlobalResults(results || []);
          setLoading(false);
        })
        .catch(() => {
          setErrorSnackBar({ code: 2301, message: 'Missing global results.', severity: 'warning' });
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [userData, group.data[currentGroup]._id]);

  // eslint-disable-next-line max-len
  // const isIdle = useTimeCounter(userId, groupId || null);

  // eslint-disable-next-line no-unused-expressions
  // isIdle && logout();

  return requestingCourses || loading ? (<DashboardLoader />) : (
    globalResults.length > 0 ? (
      <Container className={classes.root}>
        <Dictionnary />
        <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
            {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
          </Alert>
        </Snackbar>
        <Grid container direction="row">
          <Grid item md={12} xs={12}>
            <GlobalResults
              studentData={userData}
              currentGroup={group.data[currentGroup]}
              studentId={userData._id}
              data={globalResults}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" style={{ marginLeft: 12 }}>
          <Grid item md={12} xs={12}>
            {globalResults[0].length > 0 && globalResults[0].map((module) => (
              <ResultsPanel
                key={module._id}
                module={module}
                studentId={userData._id}
              />
            ))}
          </Grid>
        </Grid>
      </Container>
    ) : (
      <>
        <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
            {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
          </Alert>
        </Snackbar>
        <p>Nothing to display here</p>
      </>
    ));
};

Results.propTypes = {
  // logout: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  requestingCourses: PropTypes.bool.isRequired,
  currentGroup: PropTypes.number.isRequired,
  userData: PropTypes.object.isRequired,
  group: PropTypes.object.isRequired,
  changePageTitle: PropTypes.func.isRequired,
  // userId: PropTypes.string.isRequired,
  // groupId: PropTypes.string.isRequired,
};

const mapState = (state) => {
  const { group } = state;
  const { authData } = state.auth;
  const { data, coursesRequested, requestingCourses } = state.module;
  const { userData } = state.user;
  const { currentGroup } = state.ui;
  const { _id } = authData;
  return {
    // eslint-disable-next-line max-len
    group, data, coursesRequested, requestingCourses, currentGroup, userData, userId: _id, groupId: group.data[currentGroup]._id,
  };
};

const actionCreators = {
  getAllModules: moduleActions.getAll,
  changePageTitle: uiActions.changePageTitle,
  logout: userActions.logout,
};

export default connect(mapState, actionCreators)(Results);
