/* eslint-disable no-nested-ternary */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  Button, Typography, Grid,
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles, useTheme } from '@material-ui/core/styles';

import placementTestActions from '../../../redux/actions/placementTestActions';
import config from '../../../config';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '90vh',
    marginTop: theme.spacing(1),
  },
  mainTitle: {
    marginTop: '1em',
    textAlign: 'center',
  },
  image: {
    width: '35%',
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  textCard: {
    margin: '2em',
    padding: '2em',
  },
  titleword1: {
    color: theme.palette.primary.main,
  },
  titleword2: {
    color: theme.palette.secondary.main,
  },
}));

const Landing = ({
  initTest, codeId, testId, _id, requestingTest, companyType,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  const handleInitTest = () => {
    initTest(codeId, { user: _id, type: 1, testId }, companyType);
  };

  return (
    <Grid container direction="row" justifyContent="center">
      <Grid item md={12}>
        <Typography className={classes.mainTitle} variant="h4">
          Bienvenue sur
          <span className={classes.titleword1}> Medical </span>
          <span className={classes.titleword2}>World </span>
        </Typography>
      </Grid>
      {!xs && (
      <Grid item md={12}>
        <img className={classes.image} alt="placement test illustration" src={`${config.OBJECT_URL}/thumbnail/pt-landing.svg`} />
      </Grid>
      )}
      <Grid item md={12} className={classes.textCard}>
        <Typography variant="h5">
          <b>Important: </b>
          {/* eslint-disable-next-line max-len */}
          Pensez à désactiver tout plugin de traduction (comme Google Translate) afin de garantir le bon fonctionnement de la plateforme.
        </Typography>
        <Typography variant="body1" style={{ marginTop: '20px' }}>
          Avant d&apos;accéder à votre contenu, vous devez réaliser votre test de placement.
        </Typography>
        <Typography variant="body1">
          Il dure approximativement 45 minutes et doit être passé en une fois,
          soyez sûr d&apos;avoir assez de temps devant vous avant de commencer.
        </Typography>
        <Typography variant="body1">
          Le test est composé de 3 parties :
        </Typography>
        <ul>
          <Typography component="li" variant="body1">Connaissance générale de la langue</Typography>
          <Typography component="li" variant="body1">Compréhension orale</Typography>
          <Typography component="li" variant="body1">Compréhension écrite</Typography>
        </ul>
        <Typography variant="body1">
          Les réponses que vous donnerez seront déterminantes
          pour évaluer votre niveau et conditionneront
          les cours qui vous seront proposés. Il est donc très important que vous
          preniez votre temps avant de répondre. Bon courage!
        </Typography>
      </Grid>
      <Button
        color="primary"
        variant="contained"
        disabled={requestingTest}
        style={{ marginBottom: '50px' }}
        onClick={() => handleInitTest()}
      >
        {requestingTest ? 'Chargement en cours' : 'Commencer votre test de placement'}
      </Button>
    </Grid>
  );
};

Landing.propTypes = {
  initTest: PropTypes.func.isRequired,
  codeId: PropTypes.string.isRequired,
  testId: PropTypes.string,
  companyType: PropTypes.string,
  requestingTest: PropTypes.bool.isRequired,
  _id: PropTypes.string.isRequired,
};

Landing.defaultProps = {
  testId: null,
  companyType: null,
};

const actionCreators = {
  initTest: placementTestActions.initTest,
};

function mapState(state) {
  const { authData } = state.auth;
  const {
    requestingTest, currentQuestion,
  } = state.placementTest;
  const { _id, codeId, testId } = authData;
  const companyType = parseInt(state.user.userData.company.type, 10);
  return {
    codeId, _id, requestingTest, currentQuestion, testId, companyType,
  };
}
export default connect(mapState, actionCreators)(Landing);
