import placementTestConstants from '../constants/placementTestConstants';

const test = JSON.parse(localStorage.getItem('test'));
const currentQuestion = localStorage.getItem('currentQuestion');
const answers = JSON.parse(localStorage.getItem('answers'));

const initialState = {
  currentQuestion: Number(currentQuestion) || 0,
  currentAnswer: [],
  requestingTest: false,
  answers: answers || [],
  test: test || null,
  multiQuestionId: 0,
};

export default function placementTestReducer(state = initialState, action) {
  switch (action.type) {
    case placementTestConstants.INIT_REQUEST:
      return {
        ...state,
        requestingTest: true,
      };
    case placementTestConstants.INIT_SUCCESS:
      return {
        ...state,
        requestingTest: false,
        test: action.test,
      };
    case placementTestConstants.INIT_FAILURE:
      return {
        ...state,
        requestingTest: false,
      };
    case placementTestConstants.SET_CURRENT_QUESTION:
      return {
        ...state,
        currentQuestion: action.currentQuestion,
      };
    case placementTestConstants.SET_MULTI_QUESTION_ID:
      return {
        ...state,
        multiQuestionId: action.multiQuestionId,
      };
    case placementTestConstants.SET_CURRENT_ANSWER:
      return {
        ...state,
        currentAnswer: action.currentAnswer,
      };
    case placementTestConstants.PUSH_ANSWER:
      return {
        ...state,
        answers: action.answers,
        currentAnswer: [],
      };
    case placementTestConstants.CLEAR_TEST:
      return {
        test: null,
        answers: [],
        currentQuestion: 0,
      };
    default:
      return state;
  }
}
