/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MCQAnswers from './MCQAnswers';
import FillBlankAnswers from './FillBlankAnswers';
import DndAnswers from './DndAnswers';
import TrueOrFalseAnswers from './TrueOrFalseAnswers';

const Answers = ({
  answers, question, type, questionId, currentAnswer, multiQuestionId,
}) => {
  switch (type) {
    case 'mcq':
      return (
        <MCQAnswers
          multiQuestionId={multiQuestionId}
          questionId={questionId}
          currentAnswer={currentAnswer}
          answers={answers}
        />
      );
    case 'fillblank':
      return (
        <FillBlankAnswers
          multiQuestionId={multiQuestionId}
          question={question}
          questionId={questionId}
          currentAnswer={currentAnswer}
          answers={answers}
        />
      );
    case 'd&d':
      return (
        <DndAnswers
          multiQuestionId={multiQuestionId}
          questionId={questionId}
          currentAnswer={currentAnswer}
          answers={answers}
        />
      );
    case 'tof':
      return (
        <TrueOrFalseAnswers
          multiQuestionId={multiQuestionId}
          questionId={questionId}
          currentAnswer={currentAnswer}
          answers={answers}
        />
      );
    default:
      return 'Unknown type';
  }
};

Answers.propTypes = {
  answers: PropTypes.array.isRequired,
  question: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  questionId: PropTypes.string.isRequired,
  multiQuestionId: PropTypes.number.isRequired,
  currentAnswer: PropTypes.array.isRequired,
};

function mapState(state) {
  const { currentAnswer } = state.placementTest;
  return { currentAnswer };
}
export default connect(mapState, {})(Answers);
