/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Grid,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { connect } from 'react-redux';
import {
  PictureAsPdf as PictureAsPdfIcon,
  PlayCircleOutline as PlayCircleOutlineIcon,
  PresentToAll as PresentToAllIcon,
} from '@material-ui/icons';
import RessourceCard from './ResourceCard';
import uiActions from '../../../../redux/actions/uiActions';
import userActions from '../../../../redux/actions/userActions';
// import useTimeCounter from '../content/useTimeCounter';
import Player from '../../../shared/Player';
import Dictionnary from '../../../Dictionnary';

const useStyles = makeStyles({
  paddingweb: {
    padding: '1em 0 10em 0',
  },
  paddingmobile: {
    padding: '1em',
    paddingLeft: '10px',
    paddingRight: '10px',
  },
});

const DisplayResources = ({
  data,
  currentGroup,
  changePageTitle,
  // logout,
  // userId,
  // groupId,
}) => {
  const [url, setUrl] = useState('');
  const classes = useStyles();
  const { innerWidth: width } = window;
  const isSmallScreen = width < 450;

  // Retrieve file type
  const getExtension = (str) => {
    const extension = str.split('.').pop();
    return extension;
  };

  useEffect(() => {
    changePageTitle('E-Learning | Resources');
  }, []);

  const handleUrl = (_url) => {
    if (_url === url) {
      setUrl('');
    } else {
      setUrl(_url);
    }
  };

  // const isIdle = useTimeCounter(userId, groupId || null);

  // eslint-disable-next-line no-unused-expressions
  // isIdle && logout();

  return (
    <Container className={!isSmallScreen ? classes.paddingweb : classes.paddingmobile}>
      <Dictionnary />
      { data[currentGroup].elearning.elearningResources.length <= 0
        ? (
          <Typography
            style={{
              width: '100%', textAlign: 'center', marginTop: 15, fontSize: '1.4em',
            }}
          >
            No additional documents have been added for this course.
          </Typography>
        )
        : null}
      {url
        ? <Player url={url} />
        : null }
      <Grid container direction="row" spacing={4}>
        {
          data[currentGroup].elearning.elearningResources.map((resource) => {
            const ext = getExtension(resource.filename);
            if (ext === 'pdf' || ext === 'pptx' || ext === 'ppt') {
              return (
                <RessourceCard
                  setUrl={handleUrl}
                  key={resource.filename}
                  title={resource.title}
                  filename={resource.filename}
                  icon={ext === 'pdf' ? <PictureAsPdfIcon color="secondary" /> : <PresentToAllIcon colot="secondary" />}
                  thumbnail={resource.thumbnail}
                  type="pdf"
                />
              );
            }
            return (
              <RessourceCard
                setUrl={handleUrl}
                key={resource.filename}
                title={resource.title}
                filename={resource.filename}
                icon={<PlayCircleOutlineIcon color="secondary" />}
                type="video"
              />
            );
          })
        }
      </Grid>
    </Container>
  );
};

DisplayResources.propTypes = {
  data: PropTypes.array.isRequired,
  currentGroup: PropTypes.number.isRequired,
  changePageTitle: PropTypes.func.isRequired,
  // logout: PropTypes.func.isRequired,
  // groupId: PropTypes.string.isRequired,
  // userId: PropTypes.string.isRequired,
};

const actionCreators = {
  changePageTitle: uiActions.changePageTitle,
  logout: userActions.logout,
};

const mapState = (state) => {
  const { data } = state.group;
  const { currentGroup } = state.ui;
  const { userData } = state.user;
  const userId = userData._id;
  return {
    data, currentGroup, groupId: data[currentGroup]._id, userId,
  };
};

export default connect(mapState, actionCreators)(DisplayResources);
