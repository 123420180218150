/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-return-assign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Loader from 'react-loader-spinner';
import {
  Typography,
  Tooltip,
  CircularProgress,
  Grid,
  List,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LinearProgressWithLabel from '../../../shared/LinearProgressWithLabel';
import UnitResultItemList from './UnitResultItemList';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  spacing: {
    flexBasis: '33.33%',
    flexShrink: 0,
  },
  doubleSpacing: {
    flexBasis: '66.66%',
    flexShrink: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontWeight: '500',
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
}));

const ResultsPanel = ({
  module,
}) => {
  const { name, _id, isRise } = module;
  const classes = useStyles();
  const [progress, setProgress] = useState(null);
  const [percentageQuiz, setPercentageQuiz] = useState(0);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const max = module.units.length;
    let success = 0;
    let percentage = 0;
    module.units.forEach((unit) => {
      if (unit.result?.success === true || unit.result?.completion === true) {
        success += 1;
      }
      if (unit.unit.isFinalQuiz
        && unit.result
        && unit.result.score
        && (unit.result.completion || unit.result.success)) {
        percentage = ((unit.result.score.raw / unit.result.score.max) * 100);
      }
    });
    success = (success / max) * 100;
    setProgress(success);
    setPercentageQuiz(percentage);
  }, []);

  const getScore = (() => {
    if (module && module.units && module.units.length > 0) {
      return module.units[0].result.score ? ((module.units[0].result.score.raw / module.units[0].result.score.max) * 100).toFixed(1) : (module.units[0].result.completion && module.units[0].result.success ? 100 : 0);
    }
    return 0;
  });

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      onChange={() => setOpen(!open)}
      expanded={open}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${_id}-content`}
        id={`panel${_id}-header`}
      >
        <Typography className={`${classes.heading} ${classes.spacing}`}>{name}</Typography>
        {
          !isRise && (module._id !== '6022645fb63d9a00bd5e08fa' && module._id !== '6022645fb63d9a00bd5e08fb') && (
            <Typography className={`${classes.secondaryHeading} ${classes.spacing}`}>
              Quiz:
              {
                progress === null
                  ? (
                    <Grid>
                      <CircularProgress size={15} />
                    </Grid>
                  )
                  : ` ${percentageQuiz.toFixed(1)} %`
              }
            </Typography>
          )
        }
        <Tooltip title="Progression du chapitre">
          <span className={`${classes.secondaryHeading} ${isRise ? classes.doubleSpacing : classes.spacing}`}>
            <Grid container direction="row">
              <Grid item md={4}>
                <Typography className={classes.secondaryHeading}>Progression: </Typography>
              </Grid>
              <Grid item md={8}>
                <LinearProgressWithLabel
                  value={progress === null ? 0 : (isRise ? getScore() : progress)}
                />
              </Grid>
            </Grid>
          </span>
        </Tooltip>
      </AccordionSummary>
      <AccordionDetails>
        {module.units === null ? (
          <Loader
            type="Oval"
            color="#00BFFF"
            height={150}
            width={150}
            style={{ margin: 'auto' }}
          />
        ) : (
          <List className={classes.root}>
            {module.units.map((unit, i) => (
              <UnitResultItemList
                module={module}
                unit={unit}
                index={i}
              />
            ))}
          </List>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

ResultsPanel.propTypes = {
  module: PropTypes.object.isRequired,
};

export default ResultsPanel;
