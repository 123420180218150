/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography,
  Card,
  Grid,
  CardActionArea,
  Snackbar,
} from '@material-ui/core';
import { Circle } from 'rc-progress';

import Alert from '@material-ui/lab/Alert';
import moduleServices from '../../../redux/services/moduleServices';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: '100%',
  },
  actionArea: {
    width: '100%',
    height: '100%',
  },
  title: {
    padding: '1em',
    paddingTop: '0px',
    textAlign: 'center',
    fontSize: '20px',
  },
  subtitle: {
    padding: '1em',
    paddingBottom: '0px',
    textAlign: 'center',
    fontSize: '17px',
    fontStyle: 'italic',
    color: '#777',
  },
  center: {
    margin: 'auto',
  },
  circleContainer: {
    padding: '15px',
  },
  fullWidth: {
    width: '100%',
    maxWidth: '100%',
  },
  percentageText: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '100%',
  },
});

const TotalProgressWidget = ({
  data, currentGroup, authData,
}) => {
  const classes = useStyles();
  const [goal, setGoal] = useState(100);
  const [isCompleted, setIsCompleted] = useState(false);
  const [totalProgress, setTotalProgress] = useState(0);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const { innerWidth: width } = window;
  const isSmallScreen = width < 450;

  useEffect(() => {
    setIsCompleted(false);
    if (data[currentGroup] && authData._id) {
      moduleServices.getTotalProgress(authData._id, data[currentGroup]._id)
        .then((res) => {
          setTotalProgress(res.percentage);
          if (data[currentGroup].elearning && data[currentGroup].elearning.elearningTarget) {
            setGoal(data[currentGroup].elearning.elearningTarget);
            if (data[currentGroup].elearning.elearningTarget <= res.percentage) {
              setIsCompleted(true);
            }
          }
        })
        .catch(() => {
          setErrorSnackBar({ code: 2701, message: 'Missing total progress data.', severity: 'error' });
        });
    }
  }, [currentGroup, totalProgress]);

  return (
    <Card direction="row" className={classes.root}>
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <CardActionArea
        component={Link}
        to={{
          pathname: '/elearning/results',
        }}
        className={classes.actionArea}
      >
        <Grid className={classes.actionArea} container direction="row" alignItems="center" alignContent="center">
          <Grid container item xs={6} md={6} direction="column" alignItems="center" alignContent="center">
            <Grid className={classes.fullWidth} item xs={4} md={4}>
              <Typography variant="body1" className={classes.title}>
                Success Rate
              </Typography>
            </Grid>
            <Grid item xs={4} md={4} className={classes.percentageText}>
              <Typography variant="h3" className={`${classes.center} ${classes.percentage}`}>
                {`${Math.trunc(totalProgress)} %`}
              </Typography>
            </Grid>
            <Grid className={classes.fullWidth} item xs={4} md={4}>
              <Typography variant="body1" className={classes.subtitle}>
                {`Goal : ${goal}%`}
              </Typography>
            </Grid>
          </Grid>
          <Grid className={classes.circleContainer} item xs={6} md={6}>
            { isSmallScreen
              ? (
                <Circle
                  percent={totalProgress}
                  strokeWidth="9"
                  trailWidth="9"
                  strokeColor={isCompleted ? '#66DD66' : '#16b1e4'}
                  style={{ width: '75%', marginLeft: '11%' }}
                />
              )
              : (
                <Circle
                  percent={totalProgress}
                  strokeWidth="9"
                  trailWidth="9"
                  strokeColor={isCompleted ? '#66DD66' : '#16b1e4'}
                  style={{ width: '9rem' }}
                />
              )}
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  );
};

TotalProgressWidget.propTypes = {
  currentGroup: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  authData: PropTypes.object.isRequired,
};

function mapState(state) {
  const { data } = state.group;
  const { authData } = state.auth;
  const { currentGroup } = state.ui;
  return {
    data, authData, currentGroup,
  };
}

export default connect(mapState, {})(TotalProgressWidget);
