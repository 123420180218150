export default {
  // Theme constants
  GET_THEMES_REQUEST: 'GET_THEMES_REQUEST',
  GET_THEMES_SUCCESS: 'GET_THEMES_SUCCESS',
  GET_THEMES_FAILURE: 'GET_THEMES_FAILURE',

  // Topic constants
  GET_TOPICS_REQUEST: 'GET_TOPICS_REQUEST',
  GET_TOPICS_SUCCESS: 'GET_TOPICS_SUCCESS',
  GET_TOPICS_FAILURE: 'GET_TOPICS_FAILURE',

  // Links of the topic constants
  GET_TOPICS_LINKS_REQUEST: 'GET_TOPICS_LINKS_REQUEST',
  GET_TOPICS_LINKS_SUCCESS: 'GET_TOPICS_LINKS_SUCCESS',
  GET_TOPICS_LINKS_FAILURE: 'GET_TOPICS_LINKS_FAILURE',

  // Links of the theme constants
  GET_LINKS_REQUEST: 'GET_LINKS_REQUEST',
  GET_LINKS_SUCCESS: 'GET_LINKS_SUCCESS',
  GET_LINKS_FAILURE: 'GET_LINKS_FAILURE',
};
