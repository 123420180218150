import {
  apiClient,
  handleResponse,
} from './axios';

/* Test getter
    function getGlossary() {
      return axios.get(`https://random-word-api.herokuapp.com/word?number=100`)
      .then(handleResponse);
    }
  */

async function getGlossary() {
  const response = await apiClient.get('/glossary/');
  return handleResponse(response);
}

async function addGlossaryWord(body, letterId) {
  const response = await apiClient.put(`/glossary/${letterId}`, body);
  return handleResponse(response);
}

async function deleteGlossaryWord(word) {
  const response = await apiClient.delete(`/glossary/${word}`);
  return handleResponse(response);
}

const glossaryServices = {
  getGlossary,
  addGlossaryWord,
  deleteGlossaryWord,
};

export default glossaryServices;
