/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Breadcrumbs as MUIBreadcrumbs,
  Button,
  Typography,
} from '@material-ui/core';

const Breadcrumbs = ({ history, location: { pathname } }) => {
  const pathnames = pathname.split('/').filter((x) => x);

  /**
   * Capitalize route name.
   * @param {String} str
   */
  const capitalize = (str) => {
    const capitalizedStr = str.charAt(0).toUpperCase() + str.slice(1);
    return capitalizedStr;
  };

  return (
    <MUIBreadcrumbs>
      {
        pathnames.map((name, i) => {
          const routeTo = `/${pathnames.slice(0, i + 1).join('/')}`;
          return (
            <Button
              key={name}
              onClick={() => { history.push(routeTo); }}
            >
              <Typography variant="body1">
                { capitalize(name) }
              </Typography>
            </Button>
          );
        })
      }
    </MUIBreadcrumbs>
  );
};

Breadcrumbs.propTypes = {
  history: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
};

export default withRouter(Breadcrumbs);
