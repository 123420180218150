/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import {
  Typography, Card, CardActionArea, Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import LinearProgressWithLabel from '../../shared/LinearProgressWithLabel';
import { addUnitTimers, sec2time } from '../../../helpers/utils';
import moduleServices from '../../../redux/services/moduleServices';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
}));

const TotalTimeWidget = ({ data, currentGroup, authData }) => {
  const classes = useStyles();
  const [totalTimePercent, setTotalTimePercent] = useState(0);
  const [timeFormatedState, setTimeFormatedState] = useState(null);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [totalTimeFormatedState, setTotalTimeFormatedState] = useState(null);
  const [refresh, setRefresh] = useState(false);

  setInterval(() => {
    setRefresh(!refresh);
  }, 30000);

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && data[currentGroup]) {
      moduleServices.getTracker(authData._id, data[currentGroup]._id)
        .then(
          (res) => {
            const { elearningDuration } = data[currentGroup].elearning;
            let timeInSecs = addUnitTimers(res.units);
            moduleServices.getIdleTracker(authData._id, data[currentGroup]._id)
              .then((_res) => {
                if (_res.units) {
                  _res.units.forEach((unit) => {
                    if (unit.unit === data[currentGroup]._id) {
                      timeInSecs += unit.time;
                    }
                  });
                }
                const percent = elearningDuration ? ((timeInSecs / 60) / elearningDuration) * 100 : 100;
                const timeFormated = sec2time(timeInSecs);
                const totalTimeInHour = sec2time(elearningDuration * 60);
                setTotalTimePercent(percent > 100 ? 100 : percent);
                setTimeFormatedState(timeFormated);
                setTotalTimeFormatedState(totalTimeInHour);
              })
              .catch(() => {
                setErrorSnackBar({ code: 1401, message: 'Missing idle tracker.', severity: 'warning' });
                const percent = elearningDuration ? ((timeInSecs / 60) / elearningDuration) * 100 : 100;
                const timeFormated = sec2time(timeInSecs);
                const totalTimeInHour = sec2time(elearningDuration * 60);
                setTotalTimePercent(percent > 100 ? 100 : percent);
                setTimeFormatedState(timeFormated);
                setTotalTimeFormatedState(totalTimeInHour);
              });
          },
          () => {
            setTotalTimePercent(0);
            setTimeFormatedState('00h00');
            setTotalTimeFormatedState('00h00');
          },
        );
    }
    return () => {
      isCancelled = true;
    };
  }, [currentGroup, refresh]);

  return (
    <Card>
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <CardActionArea
        component={Link}
        to={{
          pathname: '/elearning/results',
        }}
        className={classes.root}
      >
        <Typography variant="body1" color="textPrimary">
          Total connection time
        </Typography>
        <LinearProgressWithLabel value={totalTimePercent} />
        <Typography variant="body1">
          {timeFormatedState === null || totalTimeFormatedState === null ? 'Loading...' : `${timeFormatedState} / ${totalTimeFormatedState}`}
        </Typography>
      </CardActionArea>
    </Card>
  );
};

TotalTimeWidget.propTypes = {
  currentGroup: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  authData: PropTypes.object.isRequired,
};

function mapState(state) {
  const { data } = state.group;
  const { authData } = state.auth;
  const { currentGroup } = state.ui;
  return {
    data, authData, currentGroup,
  };
}

export default connect(mapState, {})(TotalTimeWidget);
