import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import FillBlankHole from './FillBlankHole';

const useStyles = makeStyles(() => ({
  root: {
    margin: '1em auto',
    textAlign: 'center',
    fontSize: '1.5em',
    lineHeight: '50px',
    maxWidth: '80%',
  },
}));

const FillBlankQuestion = ({ question }) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const [splittedQuestion, setSplittedQuestion] = useState([]);
  const [totalHoles, setTotalHoles] = useState(0);

  useEffect(() => {
    const splitted = question.split(/[[\]]/g);
    const splittedWithHoles = [];
    let holeCount = 0;
    splitted.forEach((item) => {
      if (item.substring(0, 5) === 'HOLE_') {
        splittedWithHoles.push({ isHole: true, holeIndex: holeCount });
        holeCount += 1;
      } else {
        splittedWithHoles.push({ isHole: false, word: item });
      }
    });
    setTotalHoles(holeCount);
    setSplittedQuestion(splittedWithHoles);
  }, [question, totalHoles]);
  return (
    <Typography className={classes.root} variant="body1">
      {
        splittedQuestion.map(
          ({ word, isHole, holeIndex }, i) => (
            <FillBlankHole
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              word={word}
              isHole={isHole}
              holeIndex={holeIndex}
            />
          ),
        )
      }
    </Typography>
  );
};

FillBlankQuestion.propTypes = {
  question: PropTypes.string.isRequired,
};

export default FillBlankQuestion;
