/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import createActivityDetector from 'activity-detector';
import { Snackbar } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import userActions from '../../../redux/actions/userActions';
import uiActions from '../../../redux/actions/uiActions';
import userServices from '../../../redux/services/userServices';
import moduleServices from '../../../redux/services/moduleServices';
import { addUnitTimers, sec2time } from '../../../helpers/utils';
import groupService from '../../../redux/services/groupServices';

const TimeCounter = ({
  logout,
  moduleState,
  currentGroup,
  data,
  _id,
}) => {
  const [counter, setCounter] = useState(0);
  const [isIdle, setIsIdle] = useState(false);
  const [unitId, setUnitId] = useState(null);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (moduleState.selectedUnit && (location.pathname.includes('/elearning/course/') || location.pathname.includes('/elearning/results'))) {
      setUnitId(moduleState.selectedUnit);
    } else if (data[currentGroup] && data[currentGroup]._id) {
      setUnitId(data[currentGroup]._id);
    }
  }, [currentGroup, moduleState.selectedUnit, location.pathname]);

  useEffect(() => {
    const activityDetector = createActivityDetector({
      timeToIdle: 1000 * 60 * 30, // 30 minutes
      activityEvents: ['mousemove', 'click', 'keydown', 'DOMMouseScroll', 'mousewheel', 'mousedown', 'touchstart', 'touchmove', 'focus'],
      inactivityEvents: [],
    });
    activityDetector.on('idle', () => setIsIdle(true));
    activityDetector.on('active', () => setIsIdle(false));
    return () => activityDetector.stop();
  }, [isIdle, unitId]);

  const setUnitTracker = async () => {
    if (_id && unitId) {
      try {
        await userServices.setUnitTracker(_id, unitId, 30);
        setCounter(1);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const storeProgressTracker = async (userData, groupId) => {
    await moduleServices.globalResultsTest(userData._id, userData.email, groupId)
      .then(async (res) => {
        localStorage.setItem('progressDate', new Date());
        if (res && res.length > 0) {
          let total = 0;
          let successRate = 0;
          let tmpUnits = 0;
          res[0].forEach((module) => {
            let unitsPercent = 0;
            let totalUnits = 0;
            module.units.forEach((unit) => {
              totalUnits += 1;
              if (unit.unit.isRise && module.units[0].result.completion) {
                unitsPercent += 100;
              } else if (!unit.unit.isRise && unit.result?.completion) {
                unitsPercent += 100;
              } else if (unit.verb === 'passed' || unit.verb === 'completed') {
                unitsPercent += 100;
              }
              if (unit.unit.isFinalQuiz === true || unit.unit.isRise === true) {
                total += 1;
                if (unit.result.success || unit.result.completion) {
                  if (unit.result && unit.result.score) {
                    successRate += (
                      (unit.result.score.raw ? unit.result.score.raw : 0) / unit.result.score.max
                    ) * 100;
                  } else if (unit.result.completion && unit.result.success) {
                    successRate += 100;
                  }
                }
              }
            });
            tmpUnits += unitsPercent / totalUnits;
          });
          const completionRate = tmpUnits / res[0].length;
          successRate /= total;
          await moduleServices.getTracker(userData._id, data[currentGroup]._id)
            .then(
              (tmpRes) => {
                let { elearningDuration } = data[currentGroup].elearning;
                elearningDuration *= (data[currentGroup].elearning.elearningDurationTarget ? data[currentGroup].elearning.elearningDurationTarget / 100 : 0);
                let timeInSecs = addUnitTimers(tmpRes.units);
                moduleServices.getIdleTracker(userData._id, data[currentGroup]._id)
                  .then((_res) => {
                    if (_res.units) {
                      _res.units.forEach((unit) => {
                        if (unit.unit === data[currentGroup]._id) {
                          timeInSecs += unit.time;
                        }
                      });
                    }
                    const totalTimeInHour = sec2time(timeInSecs);
                    groupService.addProgressTrackers(userData._id, {
                      groupId: data[currentGroup]._id,
                      progress: {
                        completionRate, successRate, date: new Date(), time: totalTimeInHour, sec: timeInSecs,
                      },
                    }).catch((e) => {
                      // console.log(e)
                    });
                  })
                  .catch(() => {
                    const totalTimeInHour = sec2time(timeInSecs);
                    groupService.addProgressTrackers(userData._id, {
                      groupId: data[currentGroup]._id,
                      progress: {
                        completionRate, successRate, date: new Date(), time: totalTimeInHour, sec: timeInSecs,
                      },
                    }).catch((e) => {
                      // console.log(e)
                    });
                  });
              },
            )
            .catch((e) => console.log(e));
        }
      })
      .catch(() => {
        setErrorSnackBar({ code: 2301, message: 'Missing global results.', severity: 'warning' });
      });
  };

  useEffect(() => {
    if ((counter % 30 === 0 || counter > 30) && (unitId)) {
      setUnitTracker();
      const progressDate = localStorage.getItem('progressDate');
      if (progressDate === null) {
        const userData = localStorage.getItem('user');
        storeProgressTracker(JSON.parse(userData), data[currentGroup]._id);
      } else {
        const today = new Date();
        const tmpProgressDate = new Date(progressDate);
        if (today.getMonth() !== tmpProgressDate.getMonth() && today.getDate() !== tmpProgressDate.getDate()) {
          const userData = localStorage.getItem('user');
          storeProgressTracker(JSON.parse(userData), data[currentGroup]._id);
        }
      }
    }
  }, [counter]);

  useEffect(() => {
    const timer = setInterval(() => !isIdle && setCounter(counter + 1), 1000);
    return () => clearInterval(timer);
  }, [counter, isIdle]);

  if (!isIdle) {
    return (
      <>
        <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
          <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
            {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
          </Alert>
        </Snackbar>
      </>
    );
  }
  return logout();
};

TimeCounter.propTypes = {
  logout: PropTypes.func.isRequired,
  moduleState: PropTypes.object.isRequired,
  currentGroup: PropTypes.number.isRequired,
  data: PropTypes.array.isRequired,
  _id: PropTypes.string.isRequired,
};

function mapState(state) {
  const { currentGroup } = state.ui;
  const moduleState = state.module;
  const { userData } = state.user;
  const { data } = state.group;
  const { _id } = userData;
  return {
    _id,
    data,
    currentGroup,
    moduleState,
  };
}

const actionCreator = {
  logout: userActions.logout,
  changeDarkMode: uiActions.changeDarkMode,
};

export default connect(mapState, actionCreator)(TimeCounter);
