/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import { Card, Typography } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  wordCard: {
    margin: '1em 0.2em',
    padding: '0.5em',
  },
  word: {
    fontSize: '1.2em',
  },
}));

const Word = ({ word, index }) => {
  const classes = useStyles();
  return (
    <Draggable draggableId={word.id} index={index}>
      {(provided) => (
        <Card
          className={classes.wordCard}
          component="span"
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Typography className={classes.word} component="span" variant="body1">{word.content}</Typography>
        </Card>
      )}
    </Draggable>
  );
};

Word.propTypes = {
  index: PropTypes.string.isRequired,
  word: PropTypes.object.isRequired,
};

export default Word;
