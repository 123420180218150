import { apiClient, handleResponse } from './axios';

async function getSurvey(id, semester, companyId) {
  try {
    const response = await apiClient.get(`/survey/single/${id}/Students/${semester}/${companyId}`);
    const survey = await handleResponse(response);
    return survey;
  } catch (error) {
    return error.response;
  }
}

async function updateSurvey(id, body) {
  try {
    const response = await apiClient.put(`/survey/${id}`, body);
    const semester = await handleResponse(response);
    return semester;
  } catch (error) {
    return error.response;
  }
}
const surveysServices = {
  updateSurvey,
  getSurvey,
};

export default surveysServices;
