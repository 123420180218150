/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Snackbar, Typography } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import userActions from '../../../redux/actions/userActions';

function Alert(props) {
  // eslint-disable-next-line react/jsx-props-no-spreading
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  verifyMailText: {
    color: '#157798',
    textAlign: 'center',
  },
}));

const NewPasswordRequest = (
  { forgotPassword, requestNewPassword, newPassword },
) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [openFail, setOpenFail] = useState(false);
  const [errorMail, setErrorMail] = useState(false);
  const [email, setEmail] = useState('');

  const handleClick = (e) => {
    e.preventDefault();
    // Check if azure mail, then cancel the request
    // /^.+onmicrosoft.com$/gm
    const regex = new RegExp('^.+onmicrosoft.com$');
    const isAzure = regex.test(email);
    setErrorMail(false);
    if (isAzure === false) {
      forgotPassword(email)
        .catch(() => {
          setErrorMail(true);
        });
    } else {
      setOpen(true);
      setErrorMail(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason !== 'clickaway') {
      setOpen(false);
      setOpenFail(false);
    }
  };

  useEffect(() => {
    if (newPassword === undefined && requestNewPassword === undefined) {
      setOpenFail(true);
    }
  }, [requestNewPassword, newPassword]);

  return (
    <>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Impossible de changer le mot de passe d'un compte Microsoft.
        </Alert>
      </Snackbar>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={openFail} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="warning">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          Impossible d'envoyer un email à cette adresse.
        </Alert>
      </Snackbar>
      <form className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          error={errorMail}
          name="email"
          label="Email"
          type="email"
          id="email"
          autoComplete="new-password"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          onClick={(e) => handleClick(e)}
          disabled={requestNewPassword || newPassword}
        >
          {requestNewPassword ? 'Envoie en cours' : (newPassword ? 'Merci de verifier votre boite Mail ' : 'Demander un nouveau mot de passe')}
        </Button>
      </form>
      {!requestNewPassword && newPassword ? <Typography className={classes.verifyMailText} variant="h6">Cela peut prendre quelques minutes, pensez à vérifier votre courrier indesirable.</Typography> : null}
    </>
  );
};

NewPasswordRequest.propTypes = {
  requestNewPassword: PropTypes.bool.isRequired,
  newPassword: PropTypes.bool.isRequired,
  forgotPassword: PropTypes.func.isRequired,
};

function mapState(state) {
  const { requestNewPassword, newPassword } = state.user;
  return { requestNewPassword, newPassword };
}

const actionCreators = {
  forgotPassword: userActions.forgotPassword,
};

export default connect(mapState, actionCreators)(NewPasswordRequest);
