/* eslint-disable no-console */
import {
  apiClient,
  handleResponse,
} from './axios';

async function postExam(examResult) {
  try {
    const response = await apiClient.post('/examresult', examResult);
    const exam = await handleResponse(response);
    return exam;
  } catch (error) {
    const res = await Promise.reject(error.response.data);
    return res;
  }
}

async function getExamByUser(userId) {
  try {
    const response = await apiClient.get(`/examresult/${userId}`);
    const exams = await handleResponse(response);
    return exams;
  } catch (error) {
    const res = await Promise.reject(error.response.data);
    return res;
  }
}

async function getExamsByGroups(groups) {
  try {
    const response = await apiClient.get('/examresult/groups', { groups });
    const exams = await handleResponse(response);
    return exams;
  } catch (error) {
    const res = await Promise.reject(error.response.data);
    return res;
  }
}

async function getExamsByExamFile(examFile, groupId, userId) {
  const body = {
    examFile,
    groupId,
    userId,
  };
  try {
    const response = await apiClient.post('/examresult/examfile', body);
    const exams = await handleResponse(response);
    return exams;
  } catch (error) {
    const res = await Promise.reject(error);
    return res;
  }
}

async function getExamsByCompany(companyId) {
  try {
    const response = await apiClient.get(`/examresult/company/${companyId}`);
    const exams = await handleResponse(response);
    return exams;
  } catch (error) {
    const res = await Promise.reject(error.response.data);
    return res;
  }
}

const examResultServices = {
  postExam,
  getExamByUser,
  getExamsByGroups,
  getExamsByExamFile,
  getExamsByCompany,
};

export default examResultServices;
