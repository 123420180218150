/* eslint-disable no-console */
import {
  apiClient,
  handleResponse,
} from './axios';

const getAll = async (userId, groupId) => apiClient.get(`/module/user/${userId}/${groupId}`)
  .then(handleResponse)
  .then((res) => res)
  .catch((error) => Promise.reject(error));

const getTotalProgress = async (userId, groupId) => apiClient.get(`/module/totalquiz/${userId}/${groupId}`)
  .then(handleResponse)
  .then((res) => res)
  .catch((error) => Promise.reject(error));

const getProgressByModule = async (moduleId, userId) => apiClient.get(`/module/percentage/${moduleId}/${userId}`)
  .then(handleResponse)
  .then((res) => res)
  .catch((error) => Promise.reject(error));

const getUnits = async (moduleId, userId) => apiClient.get(`/module/progress/${moduleId}/${userId}`)
  .then(handleResponse)
  .then((res) => res)
  .catch((error) => Promise.reject(error));

const getTracker = async (userId, groupId) => apiClient.get(`unittrackers/user/${userId}/${groupId}`)
  .then(handleResponse)
  .then((res) => res)
  .catch((error) => Promise.reject(error));

const getIdleTracker = async (userId, groupId) => apiClient.get(`idletracker/user/${userId}/${groupId}`)
  .then(handleResponse)
  .then((res) => res)
  .catch((error) => Promise.reject(error));

const getLastActivity = async (userId, groupId) => apiClient.get(`/unittrackers/latest/${userId}/${groupId}`)
  .then(handleResponse)
  .then((res) => res)
  .catch((error) => Promise.reject(error));

const getExamResults = async (userId, groupId) => apiClient.get(`/exam/quizmaker/${userId}/${groupId}`)
  .then(handleResponse)
  .then((res) => res)
  .catch((error) => Promise.reject(error));

const globalResultsTest = async (userId, email, groupId) => apiClient.post(`/module/test/${userId}`, { email, groupId })
  .then(handleResponse)
  .then((units) => units)
  .catch((error) => Promise.reject(error));

const moduleServices = {
  getAll,
  getTotalProgress,
  getProgressByModule,
  getUnits,
  getTracker,
  getIdleTracker,
  getLastActivity,
  getExamResults,
  globalResultsTest,
};

export default moduleServices;
