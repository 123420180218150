import {
  apiClient,
  handleResponse,
} from './axios';

async function getRandomFunFact(userId) {
  const response = await apiClient.get(`/funfact/random/${userId}`);
  return handleResponse(response);
}

async function getFunFacts() {
  const response = await apiClient.get('/funfact');
  return handleResponse(response);
}

const funFactServices = {
  getRandomFunFact,
  getFunFacts,
};

export default funFactServices;
