/* eslint-disable react/forbid-prop-types */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import WordTable from './components/WordTable';

import uiActions from '../../redux/actions/uiActions';

const Glossary = ({ changePageTitle }) => {
  useEffect(() => {
    changePageTitle('Glossary');
  }, []);

  return (
    <WordTable />
  );
};

Glossary.propTypes = {
  changePageTitle: PropTypes.func.isRequired,
};

const actionCreators = {
  changePageTitle: uiActions.changePageTitle,
};

export default withRouter(connect(null, actionCreators)(Glossary));
