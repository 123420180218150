/* eslint-disable no-console */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
import placementTestConstants from '../constants/placementTestConstants';
import userConstants from '../constants/userConstants';
import placementTestServices from '../services/placementTestServices';
import groupActions from './groupActions';

const setCurrentQuestion = (currentQuestion) => {
  localStorage.setItem('currentQuestion', JSON.stringify(currentQuestion));
  return ({
    type: placementTestConstants.SET_CURRENT_QUESTION,
    currentQuestion,
  });
};

const setCurrentAnswer = (currentAnswer) => ({
  type: placementTestConstants.SET_CURRENT_ANSWER,
  currentAnswer,
});

const setMultiQuestionId = (id) => ({
  type: placementTestConstants.SET_MULTI_QUESTION_ID,
  id,
});

const pushAnswer = () => (dispatch, getState) => {
  const state = getState();
  const { currentAnswer } = state.placementTest;
  const answers = [...state.placementTest.answers, ...currentAnswer];
  placementTestServices.sendTest(state.placementTest.test.testId, { answers })
    .then(() => {
      // console.log('sent');
    })
    .catch((e) => console.error(e));
  localStorage.setItem('answers', JSON.stringify(answers));
  dispatch({
    type: placementTestConstants.PUSH_ANSWER,
    answers,
  });
};

function initTest(codeId, body, companyType) {
  function request() {
    return {
      type: placementTestConstants.INIT_REQUEST,
    };
  }

  function success(test) {
    return {
      type: placementTestConstants.INIT_SUCCESS,
      test,
    };
  }

  function failure(error) {
    return {
      type: placementTestConstants.INIT_FAILURE,
      error,
    };
  }

  return (dispatch) => {
    dispatch(request());
    placementTestServices.initTest(codeId || (companyType === 2 ? '5e54e9371c9d440000493b8c' : '6048da3e2e85db0028f52ebe'), body)
      .then(
        (test) => {
          localStorage.setItem('test', JSON.stringify(test));
          dispatch(success(test));
          placementTestServices.getTest(body.user).then((res) => {
            if (res && res.answers.length > 0) {
              const formattedAnswersArr = [];
              res.answers.forEach((answer) => {
                formattedAnswersArr.push({
                  answerIndex: answer.answerIndex,
                  question: answer.question._id,
                });
              });
              const answers = [...formattedAnswersArr];
              localStorage.setItem('answers', JSON.stringify(formattedAnswersArr));
              dispatch({
                type: placementTestConstants.PUSH_ANSWER,
                answers,
              });
              localStorage.setItem('currentAnswer', JSON.stringify([]));
              dispatch({
                type: placementTestConstants.SET_CURRENT_ANSWER,
                currentAnswer: [],
              });
              localStorage.setItem('currentQuestion', JSON.stringify(formattedAnswersArr.length));
              dispatch({
                type: placementTestConstants.SET_CURRENT_QUESTION,
                currentQuestion: formattedAnswersArr.length,
              });
            }
          });
        },
        (error) => {
          dispatch(failure(error ? error.toString() : 'error'));
        },
      )
      .catch((e) => dispatch(failure(e ? e.toString() : 'error')));
  };
}

const clearTest = (userId) => {
  localStorage.removeItem('test');
  localStorage.removeItem('currentQuestion');
  localStorage.removeItem('answers');
  return (dispatch, getState) => {
    // Define test is finished in localstorage in case user refreshes the page
    const testFinishedStorage = JSON.parse(localStorage.getItem('auth'));
    Object.defineProperty(testFinishedStorage, 'testFinish', { value: true });
    localStorage.removeItem('auth');
    localStorage.setItem('auth', JSON.stringify(testFinishedStorage));

    dispatch({ type: placementTestConstants.CLEAR_TEST });
    dispatch({ type: userConstants.TEST_DONE });
    dispatch(groupActions.getGroup(userId || getState().user.userData._id));
  };
};

const placementTestActions = {
  initTest,
  setMultiQuestionId,
  setCurrentQuestion,
  setCurrentAnswer,
  pushAnswer,
  clearTest,
};

export default placementTestActions;
