import groupConstants from '../constants/groupConstants';

const groupData = JSON.parse(localStorage.getItem('group'));
let initialState = {};

if (groupData) {
  initialState = {
    data: groupData,
  };
} else {
  initialState = {
    data: [],
  };
}

export default function group(state = initialState, action) {
  switch (action.type) {
    case groupConstants.GET_GROUP_REQUEST:
      return {
        ...state,
        requestingGroup: true,
      };
    case groupConstants.GET_GROUP_SUCCESS:
      return {
        ...state,
        requestingGroup: false,
        data: action.group,
      };
    case groupConstants.GET_GROUP_FAILURE:
      return {
        ...state,
        requestingGroup: false,
      };
    default:
      return state;
  }
}
