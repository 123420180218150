export default {

  GETALL_REQUEST: 'MODULES_GETALL_REQUEST',
  GETALL_SUCCESS: 'MODULES_GETALL_SUCCESS',
  GETALL_FAILURE: 'MODULES_GETALL_FAILURE',

  SELECT_MODULE: 'MODULES_SELECT_MODULE',
  SELECT_UNIT: 'MODULES_SELECT_UNIT',

  UNLOAD_MODULES: 'MODULES_UNLOAD_MODULES',
};
