/* eslint-disable max-len */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  holeAnswer: {
    fontSize: '1em',
  },
  hole: {
    padding: '0.1em 3em',
    margin: '0 0.5em',
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: ' 5px',
  },
}));

const FillBlankHole = ({
  // eslint-disable-next-line no-unused-vars
  currentAnswer, questions, multiQuestionId, word, isHole, holeIndex,
}) => {
  // eslint-disable-next-line no-unused-vars
  const classes = useStyles();
  const currentQuestion = localStorage.getItem('currentQuestion') !== null ? parseInt(localStorage.getItem('currentQuestion'), 10) : 0;

  return (isHole) ? (
    <>
      {(
        currentAnswer[multiQuestionId] && (questions[currentQuestion].answers[currentAnswer[multiQuestionId].answerIndex[holeIndex]] !== undefined)
      ) ? (
        <Typography
          className={classes.holeAnswer}
          style={{ fontSize: 19, lineHeight: 1.5 }}
          component="span"
          color="primary"
          variant="body1"
        >
          {` ${questions[currentQuestion].answers[currentAnswer[multiQuestionId].answerIndex[holeIndex]].text} `}
        </Typography>
        )
        : <span className={classes.hole} />}
    </>
  ) : (
    <span>{word}</span>
  );
};

FillBlankHole.defaultProps = {
  word: '',
  holeIndex: 0,
};

FillBlankHole.propTypes = {
  currentAnswer: PropTypes.array.isRequired,
  questions: PropTypes.array.isRequired,
  multiQuestionId: PropTypes.number.isRequired,
  isHole: PropTypes.bool.isRequired,
  holeIndex: PropTypes.number,
  word: PropTypes.string,
};

function mapState(state) {
  const {
    currentAnswer, multiQuestionId, exam,
  } = state.exam;
  const { questions } = exam;
  return {
    currentAnswer, questions, multiQuestionId,
  };
}

export default connect(mapState, {})(FillBlankHole);
