/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Typography,
  Grid,
  Tooltip,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
  Snackbar,
} from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import config from '../../../../config';
import moduleServices from '../../../../redux/services/moduleServices';
import LinearProgressWithLabel from '../../../shared/LinearProgressWithLabel';

const useStyles = makeStyles(() => ({
  imgCard: {
    height: '20vh',
  },
}));

const ModuleCard = ({
  title, id, thumbnail, _id, isRise,
}) => {
  const classes = useStyles();
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [progress, setProgress] = useState(null);

  useEffect(() => {
    let mounted = true;
    moduleServices.getProgressByModule(id, _id)
      .then((res) => {
        if (mounted) {
          setProgress(res);
        }
      })
      .catch(() => {
        setErrorSnackBar({ code: 2201, message: 'Missing progress by module.', severity: 'warning' });
      });
    // eslint-disable-next-line no-return-assign
    return () => mounted = false;
  }, [id]);

  return (
    <Grid item lg={3} md={4} sm={6} xs={12}>
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <Tooltip title={title} placement="top">
        <Card>
          <CardActionArea
            component={Link}
            to={{
              pathname: `/elearning/course/${id}`,
              state: { title },
            }}
          >
            <CardMedia
              className={classes.imgCard}
              image={`${config.OBJECT_URL}/thumbnail/${thumbnail}`}
              title={`${title} thumbnail`}
            />
            <CardContent>
              <Typography variant="body1">
                { title }
              </Typography>
              <LinearProgressWithLabel
                value={progress === null ? 0 : (isRise ? progress.passed : progress.percentage)}
              />
            </CardContent>
          </CardActionArea>
        </Card>
      </Tooltip>
    </Grid>
  );
};

ModuleCard.propTypes = {
  title: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isRise: PropTypes.string.isRequired,
  thumbnail: PropTypes.string.isRequired,
  _id: PropTypes.string.isRequired,
};

function mapState(state) {
  const { authData } = state.auth;
  const { _id } = authData;
  return { _id };
}

const connectedModuleCard = connect(mapState, {})(ModuleCard);
export default connectedModuleCard;
