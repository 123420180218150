/* eslint-disable max-len */
/* eslint-disable no-console */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/forbid-prop-types */
import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import {
  Paper, Typography, Grid, Snackbar,
} from '@material-ui/core';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import placementTestActions from '../../../redux/actions/placementTestActions';
import placementTestServices from '../../../redux/services/placementTestServices';
import { numGradeToEUgrade, convertCEFRToElearningLevel, toulouseSchools } from '../../../helpers/utils';
import config from '../../../config';

const useStyles = makeStyles(() => ({
  root: {
    width: '100%',
  },
  cardHeader: {
    backgroundColor: config.primaryColor,
    color: '#FFF',
    width: '100%',
  },
  cardBody: {
    width: '90%',
    margin: 'auto',
    textAlign: 'center',
    paddingTop: '1em',
  },
  center: {
    textAlign: 'center',
    margin: 'auto',
    width: '90%',
  },
  title: {
    padding: '1em',
    textAlign: 'center',
  },
  subTitle: {
    paddingBottom: '0.5em',
  },
  link: {
    color: config.primaryColor,
  },
}));

const NoGroupAssociatedCard = ({
  semester, company, _id, clearTest,
}) => {
  const classes = useStyles();
  const [reload, setReload] = useState(false);
  const [errorSnackBar, setErrorSnackBar] = useState(null);
  const [studentLevel, setStudentLevel] = useState(null);

  useEffect(async () => {
    async function getTest() {
      const test = await placementTestServices.getTest(_id);
      if (test && test.scores) {
        return test.scores.total;
      }
      return 1;
    }
    let level = null;
    let score;
    if (toulouseSchools.includes(company._id)) {
      level = 1;
    } else if (!studentLevel) {
      score = await getTest();
      level = convertCEFRToElearningLevel(numGradeToEUgrade(score));
      setStudentLevel(level);
    }
    placementTestServices.postStudentToGroup(company._id, studentLevel || level || 1, _id, semester)
      .then(
        () => {
          clearTest(_id);
        },
        (error) => {
          console.error(error);
          setReload(!reload);
        },
      )
      .catch(() => {
        setErrorSnackBar({ code: 1201, message: 'Missing student in group.', severity: 'error' });
        setReload(!reload);
      });
  }, [reload]);

  return (
    <Paper className={classes.root}>
      <Snackbar open={errorSnackBar !== null} onClose={() => { setErrorSnackBar(null); }} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
        <Alert severity={errorSnackBar !== null ? errorSnackBar.severity : 'info'} variant="filled" onClose={() => { setErrorSnackBar(null); }}>
          {`Error ${errorSnackBar?.code}: ${errorSnackBar?.message}`}
        </Alert>
      </Snackbar>
      <Grid container direction="row" className={classes.center}>
        <Grid item lg={12}>
          <Typography variant="body1" className={classes.title} style={{ fontWeight: 900 }}>
            Votre contenu n&apos;est pas encore disponible pour le moment.
          </Typography>
        </Grid>
        <Grid item lg={12}>
          <Typography variant="body1" className={classes.subTitle}>
            Patience, vous recevrez votre contenu prochainement.
          </Typography>
          <Typography variant="body1" className={classes.subTitle}>
            Si toutefois vous pensez que vous devriez déjà avoir accès à votre contenu,
            essayez de vous déconnecter et vous reconnecter plus tard.
            Si le contenu n&apos;est toujours pas apparu merci de contacter l&apos;
            <Link push to="/help" className={classes.link}>
              assistance
            </Link>
            .
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

NoGroupAssociatedCard.propTypes = {
  semester: PropTypes.number.isRequired,
  company: PropTypes.object.isRequired,
  _id: PropTypes.string.isRequired,
  clearTest: PropTypes.func.isRequired,
};

const actionCreators = {
  clearTest: placementTestActions.clearTest,
};

function mapState(state) {
  const { userData } = state.user;
  const { semester, company, _id } = userData;
  return {
    semester, company, _id,
  };
}
export default connect(mapState, actionCreators)(NoGroupAssociatedCard);
