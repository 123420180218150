import React from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Card,
  CardContent,
  Typography,
  ListItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import parse from 'html-react-parser';

const useStyles = makeStyles((theme) => ({
  card: {
    padding: '1em',
    margin: '0.5em',
    alignItems: 'center',
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
  },
  avatar: {
    marginLeft: '0.5em',
    backgroundColor: '#00BFFF',
  },
  text: {
    marginTop: '0.5em',
    marginLeft: '20px',
  },
}));

const NotificationCard = ({
  message, createdAt,
}) => {
  const classes = useStyles();

  /**
   * Format date to French local date dd/MM/YY at HH:mm.
   * @param {Date} date : date to format
   */
  const getDate = (date) => {
    const localeDate = new Date(date).toLocaleString('fr-FR', {
      day: '2-digit',
      month: '2-digit',
      year: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
    return localeDate;
  };

  return (
    <>
      <Card
        component={ListItem}
        className={classes.card}
      >
        <Grid container>
          <Grid item lg={12} sm={12} xs={12}>
            <CardContent>
              <Typography
                variant="body1"
                className={classes.primary}
                style={{
                  textAlign: 'center', color: 'white', padding: '10px', borderWidth: 0, borderRadius: 10, borderStyle: 'solid', maxWidth: '200px',
                }}
              >
                { getDate(createdAt) }
              </Typography>
              <Typography variant="body1" className={classes.text}>
                { parse(message) }
              </Typography>
            </CardContent>
          </Grid>
        </Grid>
      </Card>
    </>
  );
};

NotificationCard.defaultProps = {
  message: 'Default message',
  createdAt: '0000-01-01T00:00:00.000Z',
};

NotificationCard.propTypes = {
  message: PropTypes.string,
  createdAt: PropTypes.string,
};

export default NotificationCard;
