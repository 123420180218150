import {
  apiClient,
  handleResponse,
} from './axios';

async function getLastNewsByType(type) {
  const response = await apiClient.get(`/news/last/${type}`);
  return handleResponse(response);
}

const newsServices = {
  getLastNewsByType,
};

export default newsServices;
